import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';

import {
  Banner,
  LegacyCard,
  ContextualSaveBar,
  FormLayout,
  Frame,
  Layout,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
  Toast,
  Badge,
  EmptyState,
  Autocomplete,
  Icon,
  LegacyStack,
  Thumbnail,
  DropZone,
  Avatar,
  Modal,
  Button,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup, contextControlMarkup } from '../../../../components';

import { useParams } from 'react-router-dom';
import { VariantList } from './VariantList';

import { Variant } from '../types';
import { NoteMinor, PlusMinor, SearchMinor } from '@shopify/polaris-icons';
import { useUser } from '../../../../utils/PrivateRoute';
import { ProductFaq } from '../../../../types';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableItemFaq } from '../Components';

type AutocompleteCategoryOption = {
  label: string;
  value: string;
};

export function ProductDetails() {
  const params = useParams();
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [active, setActive] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const [modalRemoveIconActive, setModalRemoveIconActive] = useState(false);
  const [removeIconSpinning, setRemoveIconSpinning] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  // const [nextUrl, setNextUrl] = useState(null);
  // const [prevUrl, setPrevUrl] = useState(null);

  /**
   * Product states
   */
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [quotation, setQuotation] = useState(false);

  // FAQs
  const [productFaqs, setProductFaqs] = useState<ProductFaq[]>([]);

  // Status
  const [selectedStatus, setSelectedStatus] = useState('active');
  const statusOptions = [
    { label: 'Attivo', value: 'active' },
    { label: 'Bozza', value: 'draft' },
  ];

  const [deselectedCategoryOptions, setDeselectedCategoryOptions] = useState<AutocompleteCategoryOption[]>([]);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState([]);
  const [categoryInputValue, setCategoryInputValue] = useState('');
  const [categoryOptions, setCategoryOptions] = useState(deselectedCategoryOptions);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [category, setCategory] = useState('');

  // Variants
  const [variants, setVariants] = useState<Variant[]>([]);

  // Icon
  const [icon, setIcon] = useState<File>();
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg'];

  /**
   * Drag & Drop
   */
  const [faqs, setFaqs] = useState(['1']);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setFaqs((faqs) => {
        const oldIndex = faqs.indexOf(active.id);
        const newIndex = faqs.indexOf(over.id);

        return arrayMove(faqs, oldIndex, newIndex);
      });
    }
  }

  // Product default state
  const [defaultState, setDefaultState] = useState({
    name: '',
    description: '',
    selectedStatus: 'active',
    category: '',
    selectedProductType: 'base',
    variants: [],
    icon: {
      key: '',
      title: '',
    },
    faqs: [],
  });

  // Empty fields
  const [emptyFields, setEmptyFields] = useState({
    name: false,
    description: false,
    price: false,
    category: false,
  });

  /**
   * Fetch data:
   * - product
   * - categories
   */
  useEffect(() => {
    async function fetchProduct() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/products/${params.id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('mb__access_token')}` },
        });
        const data = response.data;

        if (data.status === 'success') {
          const product = data.data;

          setName(product.name);
          setDescription(product.description);
          setQuotation(product.quotation);
          setCategory(product.category && product.category._id ? product.category._id : '');
          setCategoryInputValue(product.category.name ? product.category.name : '');
          setSelectedStatus(product.status);
          setVariants(product.variants);

          setProductFaqs(product.faqs ? product.faqs : []);
          const faqsTmp = product.faqs ? product.faqs.map((faq: ProductFaq) => String(faq.position)) : [];
          setFaqs(faqsTmp);

          // Set default state
          setDefaultState({
            name: product.name,
            description: product.description,
            selectedStatus: product.status,
            category: product.category,
            selectedProductType: product.type,
            variants: product.variants,
            icon: {
              key: product.icon && product.icon.key ? product.icon.key : '',
              title: product.icon && product.icon.title ? product.icon.title : '',
            },
            faqs: product.faqs || [],
          });

          setLoadError(false);
        } else {
          setLoadError(true);
        }
      } catch (err: any) {
        console.log(err);
        setLoadError(true);
      }
    }
    // Categories
    const fetchCategories = async () => {
      try {
        setCategoryLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/categories`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = await response.json();

        if (data.status === 'success') {
          const categoryOptions = [];
          for (const category of data.data) {
            categoryOptions.push({
              value: category._id,
              label: `${category.name}`,
            });
          }
          setCategoryOptions(categoryOptions);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setCategoryLoading(false);
      }
    };
    fetchProduct().then(() => {
      fetchCategories();
    });
  }, [params.id, updated]);

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);

      // Check if fields are empty
      let errFlag = false;
      if (name === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: true }));
        errFlag = true;
      }

      if (description === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, description: true }));
        errFlag = true;
      }

      if (category === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, category: true }));
        errFlag = true;
      }

      if (errFlag) {
        setIsLoading(false);
        return;
      }

      // Create form data
      const formData = new FormData();
      if (icon) {
        formData.append('document', icon, icon?.name);
      }
      formData.append('name', name);
      formData.append('description', description);
      formData.append('category', category);
      formData.append('status', selectedStatus);

      // Product faqs
      if (productFaqs.length > 0) {
        productFaqs.forEach((faq: ProductFaq, index: number) => {
          formData.append(`faqs[${index}][title]`, faq.title);
          formData.append(`faqs[${index}][position]`, String(faq.position));
          formData.append(`faqs[${index}][description]`, faq.description);
        });
      }

      const response = await axios.put(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/products/${params.id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;
      setButtonSpinning(false);

      if (data.status === 'success') {
        setActive(true);
        setUpdated(!updated);
        setIsDirty(false);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setSaveError(true);
      setButtonSpinning(false);
    }
  }, [name, description, category, selectedStatus, icon, productFaqs, updated]);

  /**
   * Remove icon
   */
  const handleModalRemoveIconChange = useCallback(() => {
    setModalRemoveIconActive(!modalRemoveIconActive);
  }, [modalRemoveIconActive]);

  const handleRemoveIcon = useCallback(async () => {
    try {
      setRemoveIconSpinning(true);
      const response = await axios.delete((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/products/${params.id}/icon`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      if (data.status === 'success') {
        setIcon(undefined);
        setUpdated((updated) => !updated);
        setActive(true);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setSaveError(true);
    } finally {
      setRemoveIconSpinning(false);
      handleModalRemoveIconChange();
    }
  }, [user.organization, handleModalRemoveIconChange]);

  /**
   * Handle delete variant
   */
  const handleDeleteVariant = useCallback(
    async (variantId: string | undefined) => {
      try {
        const response = await axios.delete(
          (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/products/${params.id}/variants/${variantId}`,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('mb__access_token')}` },
            timeout: 5000,
          },
        );
        const data = response.data;

        if (data.status === 'success') {
          setVariants((variants) => variants.filter((variant: any) => variant._id !== variantId));
          setActive(true);
          setIsDirty(false);
        } else {
          setSaveError(true);
        }
      } catch (err) {
        setSaveError(true);
      }
    },
    [variants],
  );

  /**
   * Policy Handlers
   */
  const handleNameChange = useCallback(
    (e: string) => {
      // If emptyFields.name is true, set it to false
      if (emptyFields.name) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: false }));
      }
      setName(e);
      setIsDirty(true);
    },
    [emptyFields.name],
  );

  const handleDescriptionChange = useCallback(
    (e: string) => {
      // If emptyFields.description is true, set it to false
      if (emptyFields.description) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, description: false }));
      }
      setDescription(e);
      setIsDirty(true);
    },
    [emptyFields.description],
  );

  const handleStatusChange = useCallback((e: any) => {
    setSelectedStatus(e);
    setIsDirty(true);
  }, []);

  /**
   * Category autocomplete
   */
  const updateCategoryText = useCallback(
    (value: any) => {
      setCategoryInputValue(value);

      if (!categoryLoading) {
        setCategoryLoading(true);
      }

      setTimeout(() => {
        if (value === '') {
          setCategoryOptions(deselectedCategoryOptions);
          setCategoryLoading(false);
          return;
        }
        const filterRegex = new RegExp(value, 'i');
        const resultOptions = deselectedCategoryOptions.filter((option: any) => option.label.match(filterRegex));
        setCategoryOptions(resultOptions);
        setCategoryLoading(false);
      }, 300);
    },
    [deselectedCategoryOptions, categoryLoading],
  );

  const updateCategorySelection = useCallback(
    (selected: any) => {
      // Check if emptyFields is true
      if (emptyFields.category) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, category: false }));
      }

      const selectedCustomer = selected.map((selectedItem: any) => {
        const matchedOption = categoryOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption;
      });
      setSelectedCategoryOptions(selected);
      setCategoryInputValue(selectedCustomer[0].label);

      // Add customer to order
      const category = selectedCustomer[0].value;
      setCategory(category);
      setIsDirty(true);
    },
    [categoryOptions],
  );

  const categoryTextField = (
    <Autocomplete.TextField
      onChange={updateCategoryText}
      label="Categoria"
      value={categoryInputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca una categoria"
      autoComplete="off"
      error={emptyFields.category && 'Per favore seleziona una categoria'}
    />
  );

  const emptyCategoryState = (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Nessuna categoria trovata</TextContainer>
      </div>
    </React.Fragment>
  );

  /**
   * Product drop zone icon
   */
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    setIcon(acceptedFiles[0]);
    setIsDirty(true);
  }, []);

  const fileInDb = (
    <div style={{ padding: '0' }}>
      <LegacyStack alignment="center">
        <Thumbnail size="large" alt={defaultState.icon.title} source={process.env.REACT_APP_BLOB_IMAGES_URL + defaultState.icon.key} />
        <div>{defaultState.icon.title}</div>
      </LegacyStack>
    </div>
  );

  const fileUpload = !icon && defaultState.icon.key ? fileInDb : !icon ? <DropZone.FileUpload /> : null;

  const uploadedFile = icon && (
    <div style={{ padding: '0' }}>
      <LegacyStack alignment="center">
        <Thumbnail size="large" alt={icon.name} source={NoteMinor as any} />
        <div>{icon.name}</div>
      </LegacyStack>
    </div>
  );

  const iconDropZoneMarkup = (
    <DropZone onDrop={handleDropZoneDrop} allowMultiple={false}>
      {uploadedFile}
      {fileUpload}
    </DropZone>
  );

  /**
   * Remove icon modal
   */
  const removeIconAction = defaultState.icon.key
    ? [
        {
          content: 'Rimuovi icona',
          onAction: handleModalRemoveIconChange,
        },
      ]
    : undefined;

  const modalRemoveIconMarkup = (
    <Modal
      open={modalRemoveIconActive}
      onClose={handleModalRemoveIconChange}
      title="Rimuovi icona"
      primaryAction={{
        content: 'Continua',
        onAction: handleRemoveIcon,
        loading: removeIconSpinning,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleModalRemoveIconChange,
        },
      ]}
    >
      <Modal.Section>
        <p>Si è sicuri di voler procedere con la rimozione?</p>
        <p>Una volta eliminato non è più possibile tornare indietro.</p>
      </Modal.Section>
    </Modal>
  );

  /**
   * Product icon title
   */
  const productIconTitle = (
    <Avatar
      customer={false}
      size="medium"
      source={process.env.REACT_APP_BLOB_IMAGES_URL && defaultState.icon.key ? `${process.env.REACT_APP_BLOB_IMAGES_URL}${defaultState.icon.key}` : ''}
      name={'_id'}
    />
  );

  /**
   * Add faq option
   */
  const handleAddFaqOption = useCallback(() => {
    setFaqs((faqs) => [...faqs, String(faqs.length + 1)]);
  }, []);

  /**
   * Remove faq option
   */
  const handleRemoveFaq = useCallback(
    (id: string) => {
      setFaqs((faqs) => faqs.filter((faq) => faq !== id));
      // Remove the faq from the productFaqs array if it exists
      setProductFaqs((productFaqs) => {
        const newVariantOptions = productFaqs.filter((faq) => String(faq.position) !== id);
        return newVariantOptions;
      });
    },
    [setFaqs, setProductFaqs],
  );

  /**
   * Add faq option
   */
  const handleAddProductFaq = useCallback((position: string, title: string, description: string) => {
    setProductFaqs((productFaqs) => [
      ...productFaqs,
      {
        title: title,
        position: Number(position),
        description: description,
      },
    ]);
    setIsDirty(true);
  }, []);

  /**
   * Update faq option
   */
  const handleUpdateProductFaq = useCallback(
    (id: string, position: string, title: string, description: string) => {
      const tmp = productFaqs;
      const index = tmp.findIndex((faq: any) => String(faq._id) === id);
      tmp[index] = {
        position: Number(position),
        title: title,
        description: description,
      };

      setProductFaqs(tmp);
      setIsDirty(true);
    },
    [productFaqs],
  );

  /**
   * Faqs markup
   */
  const faqsMarkup = faqs.length > 0 && productFaqs.length > 0 && (
    <>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={faqs} strategy={verticalListSortingStrategy}>
          {faqs.map((id, index) => (
            <SortableItemFaq
              key={id}
              id={id}
              faqItem={productFaqs[index]}
              onDelete={handleRemoveFaq}
              onSave={handleAddProductFaq}
              onUpdate={handleUpdateProductFaq}
            />
          ))}
        </SortableContext>
      </DndContext>
      <LegacyCard.Section>
        <Button plain removeUnderline icon={PlusMinor as any} onClick={handleAddFaqOption}>
          Aggiungi FAQ
        </Button>
      </LegacyCard.Section>
    </>
  );

  /**
   * Contextual Save Bar
   */
  const handleDiscard = useCallback(() => {
    setIsDirty(false);

    // Reset all the fields to the value of defaultState
    setName(defaultState.name);
    setDescription(defaultState.description);
    setCategory(defaultState.category);
    setVariants(defaultState.variants);
    setSelectedStatus(defaultState.selectedStatus);
    setProductFaqs(defaultState.faqs);
    setFaqs(defaultState.faqs ? defaultState.faqs.map((faq: ProductFaq) => String(faq.position)) : []);
  }, [defaultState.name, defaultState.description, defaultState.category, defaultState.variants]);

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message={'Modifiche non salvate'}
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      contextControl={contextControlMarkup}
    />
  ) : null;

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati con successo." onDismiss={toggleActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Title markup
   */
  const titleMarkup = selectedStatus === 'sostituita' && (
    <Badge status="attention" progress="complete">
      Sostituita
    </Badge>
  );

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page
      title={`${defaultState.name}`}
      titleMetadata={productIconTitle}
      backAction={{ content: 'Prodotti', url: '/admin/products' }}
      // pagination={{
      //   hasNext: nextUrl && true,
      //   hasPrevious: prevUrl && true,
      //   nextURL: nextUrl && `/products/${nextUrl._id}`,
      //   previousURL: prevUrl && `/products/${prevUrl._id}`,
      // }}
    >
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}
        {/* Name & description */}
        <Layout.Section>
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout>
                <TextField label="Nome" value={name} onChange={handleNameChange} autoComplete="off" error={emptyFields.name && 'Il nome è obbligatorio'} />
                <TextField
                  label="Descrizione"
                  value={description}
                  onChange={handleDescriptionChange}
                  autoComplete="off"
                  error={emptyFields.description && 'La descrizione è obbligatoria'}
                />
              </FormLayout>
            </FormLayout>
          </LegacyCard>

          {/* Variants */}
          <LegacyCard
            title="Varianti"
            actions={[
              {
                content: 'Aggiungi variante',
                url: `/admin/products/${params.id}/variants/new`,
              },
            ]}
          >
            <LegacyCard.Section>
              <p>Aggiungi varianti se questo prodotto contiene più versioni.</p>
            </LegacyCard.Section>
            <VariantList variants={variants} quotation={quotation} productId={params.id || ''} deleteVariant={handleDeleteVariant} />
          </LegacyCard>

          {/* FAQs */}
          <LegacyCard title="FAQs">
            <LegacyCard.Section>
              <p>Aggiungi FAQ per rispondere alle domande più frequenti.</p>
            </LegacyCard.Section>
            {faqsMarkup}
          </LegacyCard>
        </Layout.Section>

        <Layout.Section secondary>
          {/* Status */}
          <LegacyCard sectioned title="Stato del prodotto">
            <Select
              label="Stato"
              options={statusOptions}
              onChange={handleStatusChange}
              value={selectedStatus}
              helpText={selectedStatus === 'active' ? 'Questo prodotto sarà disponibile sullo store.' : 'Questo prodotto sarà nascosto sullo store.'}
            />
          </LegacyCard>

          {/* Categories */}
          <LegacyCard sectioned title="Organizzazione dei prodotti">
            <FormLayout>
              <Autocomplete
                options={categoryOptions}
                selected={selectedCategoryOptions}
                onSelect={updateCategorySelection}
                loading={categoryLoading}
                textField={categoryTextField}
                emptyState={emptyCategoryState}
              />
            </FormLayout>
          </LegacyCard>

          {/* Icon */}
          <LegacyCard sectioned actions={removeIconAction} title="Icona">
            {iconDropZoneMarkup}
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  // ---- Error ----
  const errorPageMarkup = (
    <Page>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <EmptyState
              heading="Nessun prodotto presente a questo indirizzo"
              image="https://cdn.shopify.com/shopifycloud/web/assets/v1/08f1b23a19257042c52beca099d900b0.svg"
            >
              <p>Controlla l'URL e riprova oppure usa la barra di ricerca per trovare ciò di cui hai bisogno.</p>
            </EmptyState>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : loadError ? errorPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {pageMarkup}
      {toastMarkup}
      {modalRemoveIconMarkup}
    </Frame>
  );
}
