import { LegacyCard, EmptyState, Filters, Text, Badge, Pagination, IndexTable, useIndexResourceState, Select, Link, Modal, Toast } from '@shopify/polaris';
import axios from 'axios';
import { useCallback, useState, useEffect, useMemo } from 'react';
import { Customer } from '../../types';
import styles from './CustomerList.module.css';
import { useNavigate } from 'react-router-dom';
import { renderCustomerStatusBadge } from '../../utils/Common';

export function CustomerList({}) {
  const navigate = useNavigate();

  const [update, setUpdate] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [taggedWith, setTaggedWith] = useState('');
  const [queryValue, setQueryValue] = useState('');
  const [sortValue, setSortValue] = useState('DATE_CREATED_DESC');
  const [items, setItems] = useState<Customer[]>([]);
  const [frontItems, setFrontItems] = useState<Customer[]>([]);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [deletionErrors, setDeletionErrors] = useState<any[]>([]);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [toastActive, setToastActive] = useState(false);
  const [toastContent, setToastContent] = useState({ content: '' });

  const toggleToastActive = () => setToastActive(!toastActive);

  const handleTaggedWithChange = useCallback((value: any) => setTaggedWith(value), []);
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(''), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTaggedWithRemove]);

  const resourceName = {
    singular: 'cliente',
    plural: 'clienti',
  };

  const promotedBulkActions = useMemo(
    () => [
      {
        content: 'Elimina clienti',
        onAction: () => setModalActive(true),
      },
    ],
    [],
  );

  /**
   * Data fetching
   */
  useEffect(() => {
    const fetchClients = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/customers`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setItems(data.data);
          setFrontItems(data.data);
        }
      } catch (error: any) {
        console.log('❌ Errore durante il fetching dei clienti:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchClients();
  }, [update]);

  useEffect(() => {
    if (deletionErrors.length > 0) {
    }
  }, [deletionErrors]);

  /**
   * Filters
   */
  function disambiguateLabel(key: string, value: string) {
    switch (key) {
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value: any) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const filters: any[] = [];

  const appliedFilters = !isEmpty(taggedWith)
    ? [
        {
          key: 'taggedWith',
          label: disambiguateLabel('taggedWith', taggedWith),
          onRemove: handleTaggedWithRemove,
        },
      ]
    : [];

  // Filtering
  useEffect(() => {
    const filterItems = () => {
      const filteredItems = items.filter((item: Customer) => {
        const name = item.type === 'company' ? item.user?.name : item.user?.lastname ? `${item.user?.lastname} ${item.user?.name}` : item.user?.name;
        return name?.toLowerCase().includes(queryValue ? queryValue.toLowerCase() : '');
      });
      setFrontItems(filteredItems);
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, items]);

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'DATE_CREATED_DESC') {
      const tmp = [...items];
      tmp.sort((a, b) => new Date(b.date_created).getTime() - new Date(a.date_created).getTime());
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_ASC') {
      const tmp = [...items];
      tmp.sort((a, b) => new Date(a.date_created).getTime() - new Date(b.date_created).getTime());
      setFrontItems(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue, items]);

  /**
   * Index table handlers & markup
   */
  const resourceIDResolver = (customer: Customer) => {
    return customer._id;
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(frontItems, {
    resourceIDResolver,
  });

  const rowMarkup = frontItems.map((customer: Customer, index) => (
    <IndexTable.Row id={customer._id} key={customer._id} selected={selectedResources.includes(customer._id)} position={index}>
      <IndexTable.Cell>
        <Link url={`/customers/${customer._id}`} removeUnderline monochrome dataPrimaryLink>
          {customer.user?.lastname ? (
            <>
              <Text as="span" fontWeight="semibold">
                {customer.user.lastname}
              </Text>{' '}
              {customer.user.name}
            </>
          ) : (
            <Text as="span" fontWeight="semibold">
              {customer.user?.name}
            </Text>
          )}
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{renderCustomerStatusBadge(customer.user?.status ?? 'active')}</IndexTable.Cell>
      <IndexTable.Cell>
        {customer.type === 'company' ? (
          <Badge status="attention" progress="incomplete">
            Azienda
          </Badge>
        ) : (
          <Badge progress="incomplete">Privato</Badge>
        )}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {customer.address !== undefined ? (
          <>
            {customer.address?.city ? customer.address.city : <Badge>Città mancante</Badge>},&nbsp;
            {customer.address?.line ? customer.address.line : <Badge>Indirizzo mancante</Badge>},&nbsp;
            {customer.address?.zip ? customer.address.zip : <Badge>CAP mancante</Badge>}
          </>
        ) : (
          <Badge>Mancante</Badge>
        )}
      </IndexTable.Cell>
      <IndexTable.Cell>{customer.user?.email ? customer.user.email : <Badge>Mancante</Badge>}</IndexTable.Cell>
      <IndexTable.Cell>{customer.user?.phone ? customer.user.phone : <Badge>Mancante</Badge>}</IndexTable.Cell>
      <IndexTable.Cell>{customer.date_created ? new Date(customer.date_created).toLocaleDateString() : <Badge>Mancante</Badge>}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  /**
   * Empty state markup
   */
  const emptyList = (
    <EmptyState
      heading="Gestisci i clienti"
      image="https://cdn.shopify.com/shopifycloud/web/assets/v1/e7b58a8b2e612fe6cf6f8c9e53830b70.svg"
      action={{
        content: 'Aggiungi un cliente',
        onAction: () => {
          navigate('/customers/new');
        },
      }}
    >
      <p>Qua è dove puoi gestire le informazioni dei tuoi clienti</p>
    </EmptyState>
  );

  /**
   * Pagination markup
   */
  const paginationMarkup =
    items.length > 50 ? (
      <div className={styles.CustomerListFooter}>
        <Pagination
          hasPrevious={!isFirstPage}
          hasNext={!isLastPage}
          // onPrevious={handlePreviousPage}
          // onNext={handleNextPage}
        />
      </div>
    ) : null;

  /**
   * Handle bulk delete
   */
  const handleBulkDelete = async () => {
    // Verify that all selected IDs are valid
    const isValidObjectId = (id: string) => /^[0-9a-fA-F]{24}$/.test(id);
    const invalidIds = selectedResources.filter((id) => !isValidObjectId(id));
    if (invalidIds.length > 0) {
      setDeletionErrors(
        invalidIds.map((id) => ({
          customer_id: id,
          error: {
            status: 400,
            type: 'invalid_request_error',
            message: 'Invalid id format',
            param: 'customer_id',
          },
        })),
      );
      setDeleteLoading(false);
      setModalActive(false);
      return;
    }

    setDeleteLoading(true);
    setDeletionErrors([]);
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/customers/delete`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
        data: {
          customer_ids: selectedResources,
        },
      });

      if (response.status === 200) {
        // Delete successful for all customers
        setUpdate(!update);
        setSelectedItems([]);
        setToastContent({ content: 'Clienti eliminati con successo!' });
        setToastActive(true);
        clearSelection();
      } else if (response.status === 207) {
        // Some customers were not deleted
        const { errors } = response.data;
        setDeletionErrors(errors);

        setUpdate(!update);
        setSelectedItems([]);

        // Check if all errors are related to orders
        const allErrorsAreOrderRelated = errors.every((error: any) => error.message && error.message.includes('associati ad ordini'));

        if (allErrorsAreOrderRelated) {
          // If all errors are related to orders, show this specific message
          setToastContent({ content: 'Tutti i clienti selezionati non possono essere eliminati perché associati ad ordini.' });
        } else {
          // If some customers fail for other reasons, show the generic message
          setToastContent({ content: 'Tutti i clienti sono stati eliminati con successo, tranne alcuni che sono associati ad ordini.' });
        }
        setToastActive(true);
        clearSelection();
      } else {
        // All customers failed to delete for other reasons
        setToastContent({ content: 'Eliminazione fallita per tutti i clienti.' });
        setToastActive(true);
      }
    } catch (error: any) {
      // Extract errors from the response
      const errors = error.response?.data?.errors || [];

      // Check if all errors are related to orders
      const isOrderError = errors.some((err: any) => err.error?.message && err.error.message.includes('associated with existing orders'));

      if (isOrderError) {
        setToastContent({
          content: 'Non puoi eliminare clienti associati ad ordini.',
        });
      } else {
        setToastContent({
          content: "Errore durante l'eliminazione dei clienti.",
        });
      }
      setToastActive(true);
    } finally {
      setDeleteLoading(false);
      setModalActive(false);
    }
  };

  /**
   * Delete modal markup
   */
  const deleteModal = (
    <Modal
      open={modalActive}
      onClose={() => setModalActive(false)}
      title="Conferma eliminazione"
      primaryAction={{
        content: 'Elimina',
        onAction: handleBulkDelete,
        destructive: true,
        loading: deleteLoading,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: () => setModalActive(false),
        },
      ]}
    >
      <Modal.Section>
        <Text as="p">Sei sicuro di voler eliminare i clienti selezionati? Questa azione non può essere annullata.</Text>
      </Modal.Section>
    </Modal>
  );

  /**
   * Toast markup
   */
  const toastMarkup = toastActive ? <Toast content={toastContent.content} onDismiss={toggleToastActive} /> : null;

  /**
   * Customer list markup
   */
  const customerListMarkup = (
    <>
      <LegacyCard>
        {deleteModal}
        {toastMarkup}
        {selectedItems}
        <div style={{ padding: '16px', display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Filters
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              queryPlaceholder={'Filtra clienti'}
              onQueryChange={setQueryValue}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleClearAll}
            />
          </div>
          <div style={{ paddingLeft: '0.4rem' }}>
            <Select
              labelInline
              label="Ordina per"
              options={[
                { label: 'Data aggiunta cliente (dal più recente)', value: 'DATE_CREATED_DESC' },
                { label: 'Data aggiunta cliente (dal meno recente)', value: 'DATE_CREATED_ASC' },
              ]}
              value={sortValue}
              onChange={(selected) => {
                setSortValue(selected);
              }}
            />
          </div>
        </div>
        <IndexTable
          resourceName={resourceName}
          emptyState={emptyList}
          itemCount={frontItems.length}
          selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
          hasMoreItems
          loading={isLoading}
          promotedBulkActions={promotedBulkActions}
          onSelectionChange={handleSelectionChange}
          headings={[
            { title: 'Nome completo' },
            { title: 'Stato' },
            { title: 'Tipologia' },
            { title: 'Indirizzo' },
            { title: 'Email' },
            { title: 'Telefono' },
            { title: 'Data di creazione' },
          ]}
          sort
        >
          {rowMarkup}
        </IndexTable>
        {paginationMarkup}
      </LegacyCard>
    </>
  );

  return customerListMarkup;
}
