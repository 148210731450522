import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  LegacyCard,
  Frame,
  Icon,
  Layout,
  Loading,
  Modal,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  ResourceList,
  ResourceItem,
  Link,
} from '@shopify/polaris';
import { NoteMinor, FileMinor } from '@shopify/polaris-icons';

import { TopBarMarkup, NavigationMarkup } from '../../components';
import styles from './QuestionnaireAll.module.scss';

import { useUser } from '../../utils/PrivateRoute';
import axios from 'axios';
import { Variant } from '../../types';
import { parseVariantFileType } from '../../utils/Common';

export function QuestionnaireAll() {
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  // Manage which product to show in the modal
  const [selectedItem, setSelectedItem] = useState<Variant | null>(null);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((prev) => !prev), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  const [items, setItems] = useState<Variant[]>([]);

  /**
   * Fetch products
   */
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/products/variants', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setItems(data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProducts();
  }, []);

  const loadingMarkup = isLoading ? <Loading /> : null;

  // Group products by insurance company (company.name).
  const groupedByCompany = items.reduce((acc: Record<string, Variant[]>, item: Variant) => {
    const companyName = item?.company?.name || 'Sconosciuta';
    if (!acc[companyName]) {
      acc[companyName] = [];
    }
    acc[companyName].push(item);
    return acc;
  }, {});

  // Modal open/close
  const handleOpenModal = (item: Variant) => setSelectedItem(item);
  const handleCloseModal = () => setSelectedItem(null);

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page title="Prodotti">
      <Layout>
        <Layout.Section>
          {Object.entries(groupedByCompany).map(([companyName, variants]) => (
            <div className={styles.CompanySection} key={companyName}>
              <LegacyCard sectioned title={companyName}>
                <ResourceList
                  resourceName={{ singular: 'variant', plural: 'variants' }}
                  items={variants}
                  renderItem={(variant) => {
                    return (
                      <ResourceItem id={variant._id} onClick={() => handleOpenModal(variant)} media={<Icon source={NoteMinor as any} />}>
                        <span>{variant.name}</span>
                      </ResourceItem>
                    );
                  }}
                />
              </LegacyCard>
            </div>
          ))}
        </Layout.Section>
      </Layout>

      {/* Modal for showing product details */}
      <Modal
        open={Boolean(selectedItem)}
        onClose={handleCloseModal}
        title={
          user.role === 'admin' ? (
            <Link url={`/admin/products/${selectedItem?.product?._id}/variants/${selectedItem?._id}`}>{selectedItem?.name || 'Dettagli prodotto'}</Link>
          ) : (
            selectedItem?.name || 'Dettagli prodotto'
          )
        }
        primaryAction={{
          content: 'Chiudi',
          onAction: handleCloseModal,
        }}
      >
        <Modal.Section>
          {selectedItem && (
            <TextContainer spacing="tight">
              <p>
                <strong>Descrizione:</strong> {selectedItem.description || 'Nessuna descrizione'}
              </p>
              <p>
                <strong>Compagnia:</strong> {selectedItem.company?.name}
              </p>
              <p>
                <strong>Tipologia:</strong> {selectedItem.type}
              </p>
              <p>
                <strong>Da quotare manualmente?</strong> {selectedItem.to_be_quoted ? 'Sì' : 'No'}
              </p>
              <p>
                <strong>Ha automazioni?:</strong> {selectedItem.has_automation_bot ? 'Si' : 'No'}
              </p>

              {/* If there are attached files */}
              {selectedItem.files && selectedItem.files.length > 0 && (
                <>
                  <p>
                    <strong>Allegati:</strong>
                  </p>
                  <ResourceList
                    resourceName={{ singular: 'allegato', plural: 'allegati' }}
                    items={selectedItem.files}
                    renderItem={(file) => {
                      return (
                        <ResourceItem
                          id={file.key}
                          accessibilityLabel={file.title}
                          media={<Icon source={FileMinor as any} />}
                          url={`${process.env.REACT_APP_BLOB_DOCUMENTS_URL}${file.key}`}
                          external
                        >
                          {/* If you want to add styling, wrap in a custom class */}
                          <span className={styles.FileLink}>{`${file.title.split('__')[1]} ${
                            file.file_type ? ` - ${parseVariantFileType(file.file_type)}` : null
                          }`}</span>
                        </ResourceItem>
                      );
                    }}
                  />
                </>
              )}
            </TextContainer>
          )}
        </Modal.Section>
      </Modal>
    </Page>
  );

  // ---- Loading skeleton ----
  const loadingPageMarkup = (
    <SkeletonPage title="Prodotti">
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {pageMarkup}
    </Frame>
  );
}
