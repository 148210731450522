import { useCallback, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, LegacyCard, FormLayout, LegacyStack, TextField, Text } from '@shopify/polaris';
import { DeleteMinor, DragHandleMinor } from '@shopify/polaris-icons';
import { ProductFaq } from '../../../../types';

interface Props {
  id: string;
  faqItem?: ProductFaq;
  onDelete: (id: string) => void;
  onSave?: (id: string, title: string, description: string) => void;
  onUpdate?: (_id: string, id: string, title: string, description: string) => void;
}

export function SortableItemFaq({ id, faqItem, onDelete, onSave, onUpdate }: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [title, setTitle] = useState(faqItem?.title ?? '');
  const [description, setDescription] = useState(faqItem?.description ?? '');
  const [isEditing, setIsEditing] = useState(faqItem === undefined);

  const [emptyFields, setEmptyFields] = useState({
    title: false,
    description: false,
  });

  const handleDelete = () => {
    onDelete(id);
  };

  // Title change handler
  const onTitleChange = useCallback(
    (value: string) => {
      if (emptyFields.title) {
        setEmptyFields({ ...emptyFields, title: false });
      }
      setTitle(value);
    },
    [emptyFields.title],
  );

  // Description change handler
  const onDescriptionChange = useCallback(
    (value: string) => {
      if (emptyFields.description) {
        setEmptyFields({ ...emptyFields, description: false });
      }
      setDescription(value);
    },
    [emptyFields.description],
  );

  /**
   * Save handler
   */
  const handleSave = () => {
    let errFlag = false;

    // Check if name is empty
    if (title === '') {
      setEmptyFields({
        ...emptyFields,
        title: true,
      });
      errFlag = true;
    }

    // Check if description is empty
    if (description === '') {
      setEmptyFields({
        ...emptyFields,
        description: true,
      });
      errFlag = true;
    }

    if (errFlag) {
      return;
    }

    // Save data if faq is undefined otherwise update data
    if (faqItem === undefined) {
      if (onSave !== undefined) onSave(id, title, description);
    } else {
      if (onUpdate !== undefined && faqItem._id !== undefined) {
        onUpdate(faqItem._id, id, title, description);
      }
    }

    // Change component to display mode
    setIsEditing(false);
  };

  /**
   * Edit mode markup
   */
  const editModeMarkup = (
    <LegacyStack.Item fill>
      <FormLayout>
        <FormLayout.Group>
          <TextField label="Titolo" value={title} onChange={onTitleChange} error={emptyFields.title && 'Il titolo è obbligatorio'} autoComplete="off" />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            label="Descrizione"
            value={description}
            onChange={onDescriptionChange}
            multiline={2}
            error={emptyFields.description && 'La descrizione è obbligatoria'}
            autoComplete="off"
          />
        </FormLayout.Group>

        <Button onClick={handleSave}>Fatto</Button>
      </FormLayout>
    </LegacyStack.Item>
  );

  /**
   * Display mode markup
   */
  const displayModeMarkup = (
    <LegacyStack.Item fill>
      <Text as="span" fontWeight="semibold">
        {title}
      </Text>
      <div>Descrizione: {description.length <= 30 ? description : description.slice(0, 30) + '...'}</div>
    </LegacyStack.Item>
  );

  return (
    <LegacyCard.Section>
      <div ref={setNodeRef} style={style}>
        <LegacyStack alignment="center">
          <LegacyStack.Item>
            {/* <Icon source={DragHandleMinor} {...attributes} {...listeners} /> */}
            <Button plain icon={DragHandleMinor as any} {...attributes} {...listeners} />
          </LegacyStack.Item>
          {isEditing ? editModeMarkup : displayModeMarkup}
          <LegacyStack.Item>
            {isEditing ? (
              <Button icon={DeleteMinor as any} plain onClick={handleDelete} />
            ) : (
              <Button size="slim" onClick={() => setIsEditing(true)}>
                Modifica
              </Button>
            )}
          </LegacyStack.Item>
        </LegacyStack>
      </div>
    </LegacyCard.Section>
  );
}
