import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { saveAs } from 'file-saver';

import {
  Avatar,
  Button,
  LegacyCard,
  EmptyState,
  DropZone,
  Modal,
  FormLayout,
  TextField,
  Frame,
  Labelled,
  Layout,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  LegacyStack,
  TextContainer,
  Text,
  Select,
  Banner,
  Toast,
  Badge,
  Link,
  ContextualSaveBar,
  Autocomplete,
  Icon,
  ResourceList,
  ResourceItem,
  Tooltip,
  VerticalStack,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup, NewCustomerPolicy, contextControlMarkup } from '../../../components';

import './CustomerDetails.scss';
import axios, { AxiosError } from 'axios';
import { Customer, CustomerFileTypes, Order } from '../../../types';
import { useUser } from '../../../utils/PrivateRoute';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { getLanguageLabel, parseCustomerFileType, parseCustomerGender, parseDateLabel, renderCustomerStatusBadge } from '../../../utils/Common';
import { generateFiscalCode } from '../../../utils/fiscalcode';
import { AddNoteMajor, ClipboardMinor, DeleteMajor, NoteMinor, SearchMinor } from '@shopify/polaris-icons';

type FileOption = {
  [x: string]: string;

  label: string;
  value: any;
};

export function CustomerDetails() {
  const params = useParams();
  const { user } = useUser();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [missingUserError, setMissingUserError] = useState(false);
  const [customerOrdersError, setCustomerOrdersError] = useState(false);

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  const [customerType, setCustomerType] = useState<'private' | 'company'>('private');
  const [vat, setVat] = useState('');
  const [ateco, setAteco] = useState('');

  const [fileUploadActive, setFileUploadActive] = useState(false);
  const toggleFileUploadActive = useCallback(() => setFileUploadActive((fileUploadActive) => !fileUploadActive), []);

  const [fileDeletedActive, setFileDeletedActive] = useState(false);
  const toggleFileDeletedActive = useCallback(() => setFileDeletedActive((fileDeletedActive) => !fileDeletedActive), []);

  const [newPolicyOrQuoteSuccess, setNewPolicyOrQuoteSuccess] = useState(false);
  const toggleSuccess = useCallback(() => setNewPolicyOrQuoteSuccess((newPolicyOrQuoteSuccess) => !newPolicyOrQuoteSuccess), []);

  // Copy
  const [toastCopyActive, setToastCopyActive] = useState(false);
  const toggleToastCopyActive = useCallback(() => setToastCopyActive((toastCopyActive) => !toastCopyActive), []);

  // Check if file added is policy or quote
  const [isPolicy, setIsPolicy] = useState(false);
  const toggleIsPolicy = useCallback(() => setIsPolicy((isPolicy) => !isPolicy), []);

  const [modalAddressActive, setModalAddressActive] = useState(false);

  const handleModalAddressChange = useCallback(() => {
    setModalAddressActive(!modalAddressActive);
  }, [modalAddressActive]);

  // Delete customer
  const [deleted, setDeleted] = useState(false);
  const [deleteButtonSpinning, setDeleteButtonSpinning] = useState(false);
  const [modalDeleteCustomerActive, setModalDeleteCustomerActive] = useState(false);
  const handleDeleteCustomerModalChange = useCallback(() => {
    setModalDeleteCustomerActive(!modalDeleteCustomerActive);
  }, [modalDeleteCustomerActive]);

  // Next or prev url
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [update, setUpdate] = useState(false);

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Customer details
   */
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [job, setJob] = useState('');

  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');

  const genderOptions = [
    { label: 'Uomo', value: 'male' },
    { label: 'Donna', value: 'female' },
  ];
  const [gender, setGender] = useState<'male' | 'female'>('male');
  const [birthDate, setBirthDate] = useState('');
  const [birthDateError, setBirthDateError] = useState({
    status: false,
    message: '',
  });
  const [birthCity, setBirthCity] = useState<{ label: string; value: string }>();
  const birthCityDeselectedOptions = useMemo(() => [], []);
  const [birthCityOptions, setBirthCityOptions] = useState<{ label: string; value: string }[]>([]);
  const [birthCitySelectedOptions, setBirthCitySelectedOptions] = useState<string[]>([]);
  const [birthCityInputValue, setBirthCityInputValue] = useState('');
  const [birthCityLoading, setBirthCityLoading] = useState(false);
  const [fiscalCode, setFiscalCode] = useState('');

  const [phone, setPhone] = useState('');

  const [line, setLine] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('IT'); // Value is country.code
  const [province, setProvince] = useState('');

  const [countryOptions, setCountryOptions] = useState<{ label: string; value: string }[]>([]); // Used to load array of countries

  // Quotes and policies
  const [policies, setPolicies] = useState<Order[]>([]);
  const [quotes, setQuotes] = useState<Order[]>([]);

  // Errors
  const [zipError, setZipError] = useState(false);

  /**
   * File Upload - Documenti
   */
  const [files, setFiles] = useState<string[]>([]);
  const [fileID, setFileID] = useState<string[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [txtFileUploadValue, setTxtFileUploadValue] = useState('');
  const [tmpFileUploadValue, setTmpFileUploadValue] = useState<any>();
  const [selectedFileUpload, setSelectedFileUpload] = useState<string[]>([]);
  const [optionsFileUpload, setOptionsFileUpload] = useState<FileOption[]>([]);
  const [activeFileUpload, setActiveFileUpload] = useState(false);
  const [isAdderFileUpload, setIsAdderFileUpload] = useState(false);
  const [selectedType, setSelectedType] = useState<string>(CustomerFileTypes.identity);

  const typeOptions = [
    { label: 'Documento di identità', value: CustomerFileTypes.identity },
    { label: 'Passaporto', value: CustomerFileTypes.passport },
    { label: 'Patente', value: CustomerFileTypes.driver_license },
    { label: 'Altro', value: CustomerFileTypes.other },
  ];

  /**
   * Data fetching
   */
  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/customers/${params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const { customer } = data.data;
          setCustomer(customer);
          setJob(customer.job ? customer.job.label : '');

          // If the customer has not a user, set error
          if (!customer.user) {
            setMissingUserError(true);
          }

          setName(customer.user?.name ?? '');
          setLastname(customer.user?.lastname ?? '');
          setPhone(customer.user?.phone ?? '');
          setFiscalCode(customer.fiscal_code ?? '');
          if (customer.birth_city) {
            setBirthCity({ label: customer.birth_city?.label ?? '', value: customer.birth_city?.value ?? '' });
            setBirthCityInputValue(customer.birth_city?.label ?? '');
          }
          if (customer.birth_date) {
            setBirthDate(parseDateLabel(dayjs(customer.birth_date).toDate()));
          } else {
            setBirthDate('');
          }
          setGender(customer.gender ?? 'male');

          // If customer has an address, set it
          if (customer.address) {
            setLine(customer.address.line);
            setCity(customer.address.city);
            setZip(customer.address.zip);
            setCountry(customer.address.country);
            setProvince(customer.address.province);
          }

          // Prev and next url
          setPrevUrl(response.data.prevUrl);
          setNextUrl(response.data.nextUrl);

          // Files
          const fileArray = [];
          const fileNamesArray = [];
          const fileIdsArray = [];
          const fileOptions = [];
          const fileTypes = [];

          for (const item of customer.files) {
            fileArray.push('Document');
            fileNamesArray.push(item.title);
            fileIdsArray.push(item._id);
            fileOptions.push({
              label: `${item.title} - ${parseCustomerFileType(item.file_type)}`,
              value: item.key,
            });
            fileTypes.push(item.file_type);
          }
          setFiles(fileArray);
          setFileNames(fileNamesArray);
          setFileID(fileIdsArray);
          setOptionsFileUpload(fileOptions);

          return customer.user?._id ?? null;
        } else {
          setError(true);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);

        setError(true);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchCountries = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/countries`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setCountryOptions(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        const status = axiosError.response?.status || 500;
        const data: any = axiosError.response?.data;
      } finally {
        setIsLoading(false);
      }
    };
    const fetchUserOrders = async (user_id: string | null) => {
      try {
        if (!user_id) return;

        setIsLoading(true);

        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/orders/user/${user_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setPolicies(data.data.policies);
          setQuotes(data.data.quotes);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCustomer()
      .then((user_id) => fetchUserOrders(user_id))
      .then(() => fetchCountries());
  }, [update, params.id]);

  /**
   * Update
   */
  const [modalActive, setModalActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [active, setActive] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleModalChange = useCallback(() => {
    setModalActive(!modalActive);
  }, [modalActive]);

  const handleNameChange = useCallback((e: string) => {
    setName(e);
    setIsDirty(true);
  }, []);

  const handleLastnameChange = useCallback((e: string) => {
    // Do not consent numbers
    if (!e.match(/^[0-9]*$/)) {
      setLastname(e);
      setIsDirty(true);
    }
  }, []);

  const handleFiscalCodeChange = useCallback((e: string) => {
    setFiscalCode(e);
    setIsDirty(true);
  }, []);

  /**
   * Handle generate fiscal code
   */
  const handleGenerateFC = useCallback(() => {
    // Check if all fields are filled
    let isFilled = true;
    console.log(name, lastname, birthDate, gender, birthCity);
    if (name === '') isFilled = false;
    if (lastname === '') isFilled = false;
    if (birthDate === '') isFilled = false;
    if (!birthCity?.value) isFilled = false;
    if (gender !== 'male' && gender !== 'female') isFilled = false;

    if (isFilled && birthCity?.value) {
      // Generate fiscal code italy
      const computedFiscalCode = generateFiscalCode(name, lastname, new Date(birthDate), birthCity.value, gender);
      setFiscalCode(computedFiscalCode);
      setIsDirty(true);
    } else {
      alert('Compila tutti i campi per generare il codice fiscale');
      // TODO: show error
    }
  }, [name, lastname, birthDate, birthCity, gender]);

  // Gender
  const handleGenderChange = useCallback((e: any) => {
    setGender(e);
    setIsDirty(true);
  }, []);

  const handlePhoneChange = useCallback((e: string) => {
    // Allow only numbers
    if (e.match(/^[0-9]*$/)) {
      setPhone(e);
      setIsDirty(true);
    }
  }, []);

  // Country
  const handleCountryChange = useCallback((e: any) => {
    setCountry(e);
  }, []);

  const handleLineChange = useCallback((e: string) => {
    setLine(e);
  }, []);

  const handleCityChange = useCallback((e: string) => {
    setCity(e);
  }, []);

  const handleZipChange = useCallback(
    (e: string) => {
      if (zipError) {
        setZipError(false);
      }

      // Allow only numbers
      if (e.match(/^[0-9]*$/)) {
        setZip(e);
      }

      // Allow only 5 numbers
      if (e.length > 5) {
        setZip(e.substring(0, 5));
      }
    },
    [zipError],
  );

  // Birth date
  const handleBirthDateChange = useCallback((e: string) => {
    const date = dayjs(e);

    // Check if the date is in the past
    if (date.isAfter(dayjs())) {
      return;
    }

    setBirthDate(e);
    setIsDirty(true);
  }, []);

  // Birth City
  const updateBirthCityText = useCallback(
    (value: string) => {
      setBirthCityInputValue(value);

      if (!birthCityLoading) {
        setBirthCityLoading(true);
      }

      if (value === '') {
        setBirthCityOptions(birthCityDeselectedOptions);
        setBirthCityLoading(false);
        return;
      }

      // When the user has typed at least 3 characters, request
      // the list from the server
      if (value.length < 3) {
        setBirthCityLoading(false);
        return;
      } else {
        // Fetch birth city options
        setBirthCityLoading(true);
        axios
          .get(`${process.env.REACT_APP_API_URL ?? '/api'}/user/cadastral_codes/search/${value}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          })
          .then((response) => {
            const data = response.data;

            if (data.status === 'success') {
              setBirthCityOptions(data.data);
              setIsDirty(true);
            }
          })
          .catch((error) => {
            const axiosError = error as AxiosError;
            console.log(axiosError);
          })
          .finally(() => {
            setBirthCityLoading(false);
          });
      }
    },
    [birthCityOptions, birthCityLoading, birthCityDeselectedOptions],
  );

  const updateBirthCitySelection = useCallback(
    (selected: string[]) => {
      const selectedText = selected.map((selectedItem) => {
        const matchedOption = birthCityOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });
      setBirthCitySelectedOptions(selected);
      setBirthCity({
        label: selectedText[0] || '',
        value: selected[0] || '',
      });
      setBirthCityInputValue(selectedText[0] || '');
    },
    [birthCityOptions],
  );

  /**
   * Update customer
   */
  const handleSave = useCallback(
    async (activate?: boolean) => {
      try {
        setButtonSpinning(true);
        const payload = {
          name: name,
          lastname: customerType === 'private' ? lastname : '',
          fiscal_code: customerType === 'private' ? fiscalCode : '',
          phone: phone,
          birth_city: customerType === 'private' ? birthCity : null,
          birth_date: customerType === 'private' ? birthDate : null,
          type: customerType,
          vat: customerType === 'company' ? vat : '',
          ateco: customerType === 'company' ? ateco : '',
        };

        const response = await axios.put(`${process.env.REACT_APP_API_URL ?? `/api`}/admin/customers/${params.id}`, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setActive(true);
          setUpdate(!update);
          setIsDirty(false);
        } else {
          setSaveError(true);
        }
      } catch (error) {
        console.log(error);
        setSaveError(true);
      } finally {
        setButtonSpinning(false);
        if (!activate) {
          handleModalChange();
        }
      }
    },
    [params.id, name, lastname, fiscalCode, phone, update, birthCity, birthDate, customerType, vat, ateco],
  );

  /**
   * Add customer address
   */
  const handleAddAddress = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL ?? '/api'}/admin/customers/${params.id}/address`,
        {
          country: country,
          city: city,
          line: line,
          zip: zip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setActive(true);
        setUpdate(!update);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);

      setSaveError(true);
    } finally {
      setButtonSpinning(false);
      handleModalAddressChange();
    }
  }, [handleModalAddressChange, params.id, country, line, city, zip, update]);

  /**
   * Delete customer
   */
  const handleDeleteCustomer = useCallback(async () => {
    try {
      setDeleteButtonSpinning(true);
      const response = await axios.delete(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/customers/${params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      if (data.status === 'success') {
        setDeleted(true);
        // Redirect to customers after 3 seconds
        setTimeout(() => {
          navigate('/customers');
        }, 3000);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      const status = axiosError.response?.status || 500;

      if (status === 409) {
        setCustomerOrdersError(true);
      } else {
        setSaveError(true);
      }
    } finally {
      setDeleteButtonSpinning(false);
      handleDeleteCustomerModalChange();
    }
  }, [handleDeleteCustomerModalChange, params.id]);

  /**
   * File Uploading Handler
   */
  const toggleModalFileUpload = useCallback(() => setActiveFileUpload((activeFileUpload: boolean) => !activeFileUpload), []);
  const toggleIsAdderFileUpload = useCallback((e: boolean) => {
    setIsAdderFileUpload((isAdderFileUpload) => {
      isAdderFileUpload = e;
      return isAdderFileUpload;
    });
  }, []);

  const handleFileUploadAdd = useCallback(
    (e: any, fn: string, type: any) => {
      if (e === '' || e === undefined || fn === '' || fn === undefined || type === undefined || type === '') return;

      setFiles((files) => {
        const newFiles = files;
        //@ts-ignore
        newFiles.push(e);
        return newFiles;
      });

      setFileNames((fileNames) => {
        const newFileNames = fileNames;
        //@ts-ignore
        newFileNames.push(fn);
        return newFileNames;
      });

      setOptionsFileUpload((optionsFileUpload) => {
        const newOptions = optionsFileUpload;
        //@ts-ignore
        newOptions.push({ label: `${fn.split('__')[1]} - ${type}`, value: fn });
        return newOptions;
      });

      const addFile = async () => {
        try {
          const fd = new FormData();
          fd.append('document', e);
          fd.append('type', type);

          const response = await axios.post(
            (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/customers/${params.id}/upload`,
            fd,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
              },
            },
          );

          const data = response.data;
          if (data.status === 'success') {
            setFileUploadActive(true);
          }
        } catch (error) {
          console.log(error);
          setError(true);
        }
      };
      addFile();
    },
    [params.id, typeOptions],
  );

  useEffect(() => {
    if (customer) {
      setCustomerType(customer.type as 'private' | 'company');
      setVat(customer.vat || '');
      setAteco(customer.ateco || '');
    }
  }, [customer]);

  const customerTypeOptions = [
    { label: 'Privato', value: 'private' },
    { label: 'Azienda', value: 'company' },
  ];

  const handleCustomerTypeChange = useCallback((value: string) => {
    setCustomerType(value as 'private' | 'company');
    setIsDirty(true);
  }, []);

  const handleVatChange = useCallback((value: string) => {
    setVat(value);
    setIsDirty(true);
  }, []);

  const handleAtecoChange = useCallback((value: string) => {
    setAteco(value);
    setIsDirty(true);
  }, []);

  const handleConfirmFileUpload = useCallback(() => {
    if (isAdderFileUpload) {
      handleFileUploadAdd(tmpFileUploadValue, txtFileUploadValue, selectedType);
    }
    // else {
    //   handleFileUploadModify(tmpFileUploadValue, txtFileUploadValue, selectedFileUpload[0]);
    // }

    toggleModalFileUpload();
  }, [isAdderFileUpload, toggleModalFileUpload, handleFileUploadAdd, tmpFileUploadValue, txtFileUploadValue, selectedType, selectedFileUpload]);

  const handleTmpFileUploadValue = useCallback((e: any) => {
    setTmpFileUploadValue(e);
  }, []);

  const handleAddFileUpload = useCallback(
    (_dropFiles: any, acceptedFiles: any) => {
      toggleModalFileUpload();
      toggleIsAdderFileUpload(true);
      const blob = acceptedFiles[0];
      const file = new File([blob], params.id + '__' + acceptedFiles[0].name);
      setTxtFileUploadValue(file.name);
      // @ts-ignore
      setTmpFileUploadValue((tmpFileUploadValue) => file);
    },
    [toggleModalFileUpload, toggleIsAdderFileUpload, params.id],
  );

  const handleSelectionFileUpload = useCallback((e: any) => {
    setSelectedFileUpload(e);
  }, []);

  /**
   * Delete file
   */
  const handleDelFileUpload = useCallback(
    async (fileKey: string) => {
      if (!fileKey) return;

      const indexToDelete = optionsFileUpload.findIndex((op) => op.value === fileKey);
      if (indexToDelete === -1) return;

      // const modFileName = fileNames[indexToDelete];
      // const modFile = files[indexToDelete];
      // const modFileID = fileID[indexToDelete];

      try {
        await axios.delete(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/customers/${params.id}/files/${fileKey}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        setFileDeletedActive(true);
      } catch (error) {
        console.log(error);
        setError(true);
      }

      setFiles((prevFiles) => {
        const newFiles = [...prevFiles];
        newFiles.splice(indexToDelete, 1);
        return newFiles;
      });

      setFileNames((prevNames) => {
        const newNames = [...prevNames];
        newNames.splice(indexToDelete, 1);
        return newNames;
      });

      setFileID((prevIDs) => {
        const newIDs = [...prevIDs];
        newIDs.splice(indexToDelete, 1);
        return newIDs;
      });

      setOptionsFileUpload((prevOptions) => {
        const newOptions = [...prevOptions];
        newOptions.splice(indexToDelete, 1);
        return newOptions;
      });

      handleSelectionFileUpload(['']);
    },
    [optionsFileUpload, fileNames, files, fileID, handleSelectionFileUpload, params.id],
  );

  /**
   * Download single file
   */
  const handleOpenFile = useCallback(
    async (fileKey: string) => {
      try {
        const data = await fetch(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/customers/${params.id}/files/${fileKey}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const response = await data.blob();
        saveAs(response, fileKey);
      } catch (error) {
        console.log(error);
        setError(true);
      }
    },
    [params.id],
  );

  const fileUpload = !tmpFileUploadValue && <DropZone.FileUpload />;
  const uploadedFile = tmpFileUploadValue && (
    <LegacyStack>
      <div>
        {/*
        // @ts-ignore */}
        {tmpFileUploadValue.name.split('__')[1]}{' '}
        <Text variant="bodySm" as="p">
          {tmpFileUploadValue.size} bytes
        </Text>
      </div>
    </LegacyStack>
  );

  const toastPolicyOrQuoteMarkup = newPolicyOrQuoteSuccess ? (
    <Toast content={`${isPolicy ? 'La polizza è stata aggiunta correttamente!' : 'Il preventivo è stato aggiunto correttamente!'}`} onDismiss={toggleSuccess} />
  ) : null;

  const fileUploadJSX = (
    <div>
      <div>
        <LegacyStack vertical>
          {/* DropZone per il caricamento dei file */}
          <DropZone allowMultiple={false} onDrop={handleAddFileUpload} disabled={missingUserError}>
            <DropZone.FileUpload />
          </DropZone>

          <Layout>
            <Layout.Section>
              <ResourceList
                resourceName={{ singular: 'documento', plural: 'documenti' }}
                items={optionsFileUpload}
                renderItem={(file) => {
                  return (
                    <ResourceItem id={file.value} onClick={() => handleOpenFile(file.value)}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Icon source={NoteMinor as any} />
                          <span style={{ marginLeft: '8px' }}>{file.label}</span>
                        </div>

                        <div style={{ display: 'flex', gap: '8px' }}>
                          <Tooltip content="Scarica" preferredPosition="above">
                            <div onClick={(e) => e.stopPropagation()}>
                              <Button plain icon={<Icon source={AddNoteMajor as any} />} onClick={() => handleOpenFile(file.value)} />
                            </div>
                          </Tooltip>

                          <Tooltip content="Elimina" preferredPosition="above">
                            <div onClick={(e) => e.stopPropagation()}>
                              <Button plain icon={<Icon source={DeleteMajor as any} />} onClick={() => handleDelFileUpload(file.value)} />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </ResourceItem>
                  );
                }}
              />
            </Layout.Section>
          </Layout>
        </LegacyStack>
        <div>
          <Modal
            open={activeFileUpload}
            onClose={toggleModalFileUpload}
            title={isAdderFileUpload ? 'Conferma Aggiunta' : 'Conferma Modifica'}
            secondaryActions={[
              {
                content: 'Chiudi',
                onAction: toggleModalFileUpload,
              },
            ]}
          >
            <Modal.Section>
              <FormLayout>
                <FormLayout.Group>
                  <DropZone onDrop={handleTmpFileUploadValue}>
                    {uploadedFile}
                    {fileUpload}
                  </DropZone>
                </FormLayout.Group>
                <FormLayout.Group>
                  <Select label="Tipologia di documento" options={typeOptions} onChange={(e) => setSelectedType(e)} value={selectedType} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Button primary onClick={handleConfirmFileUpload}>
                    {isAdderFileUpload ? 'Conferma Aggiunta' : 'Conferma Modifica'}
                  </Button>
                </FormLayout.Group>
              </FormLayout>
            </Modal.Section>
          </Modal>
        </div>
      </div>
    </div>
  );

  /**
   * New quote or policy
   */
  const [modalQuoteActive, setModalQuoteActive] = useState(false);
  const handleModalQuoteChange = useCallback(() => {
    setModalQuoteActive(!modalQuoteActive);
  }, [modalQuoteActive]);

  /** Birth city autocomplete */
  const birthCityTextField = (
    <Autocomplete.TextField
      label="Luogo di nascita"
      autoComplete="off"
      value={birthCityInputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca"
      onChange={updateBirthCityText}
    />
  );

  const birthCityEmptyState = (
    <React.Fragment>
      <Icon source={SearchMinor as any} />
      <div style={{ textAlign: 'center' }}>Non sono presenti risultati</div>
    </React.Fragment>
  );

  /**
   * Modals markup
   */
  const modalMarkup = modalActive && (
    <Modal
      open={modalActive}
      onClose={handleModalChange}
      title="Modifica cliente"
      primaryAction={{
        content: 'Salva',
        onAction: () => handleSave(false),
        disabled: !isDirty,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: handleModalChange,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          {/* Primo gruppo: Nome e, se privato, Cognome */}
          <FormLayout.Group>
            <TextField autoComplete="on" type="text" label="Nome" value={name} onChange={handleNameChange} />
            {customerType === 'private' && <TextField autoComplete="on" type="text" label="Cognome" value={lastname} onChange={handleLastnameChange} />}
          </FormLayout.Group>
          <FormLayout.Group>
            <Select label="Tipo anagrafica" options={customerTypeOptions} value={customerType} onChange={handleCustomerTypeChange} />
          </FormLayout.Group>

          {customerType === 'private' && (
            <FormLayout.Group>
              <Select label="Sesso" options={genderOptions} value={gender} onChange={handleGenderChange} />
              <TextField
                autoComplete="on"
                type="text"
                label="Codice Fiscale"
                value={fiscalCode}
                onChange={handleFiscalCodeChange}
                labelAction={{
                  content: 'Genera',
                  onAction: handleGenerateFC,
                }}
              />
            </FormLayout.Group>
          )}
          {customerType === 'private' && (
            <FormLayout.Group>
              <TextField autoComplete="on" type="tel" prefix="+39" label="Numero di telefono" value={phone} onChange={handlePhoneChange} />
            </FormLayout.Group>
          )}
          {customerType === 'private' && (
            <FormLayout.Group>
              <TextField autoComplete="on" type="date" label="Data di nascita" value={birthDate} onChange={handleBirthDateChange} />
              <Autocomplete
                options={birthCityOptions}
                selected={birthCitySelectedOptions}
                onSelect={updateBirthCitySelection}
                textField={birthCityTextField}
                emptyState={birthCityEmptyState}
                loading={birthCityLoading}
              />
            </FormLayout.Group>
          )}

          {customerType === 'company' && (
            <FormLayout.Group>
              <TextField autoComplete="on" type="text" label="Partita IVA" value={vat} onChange={handleVatChange} />
              <TextField autoComplete="on" type="text" label="Codice ATECO" value={ateco} onChange={handleAtecoChange} />
            </FormLayout.Group>
          )}
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  const modalAddressMarkup = modalAddressActive && (
    <Modal
      open={modalAddressActive}
      onClose={handleModalAddressChange}
      title={customer?.address ? 'Modifica Indirizzo' : 'Aggiungi indirizzo'}
      primaryAction={{
        content: customer?.address ? 'Modifica' : 'Aggiungi',
        onAction: handleAddAddress,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleModalAddressChange,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <Select label="Stato" options={countryOptions} onChange={handleCountryChange} value={country} />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField autoComplete="on" type="text" label="Indirizzo" value={line} onChange={handleLineChange} />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField autoComplete="on" type="text" label="Città" value={city} onChange={handleCityChange} />
            <TextField autoComplete="on" type="text" label="CAP" value={zip} onChange={handleZipChange} error={zipError && 'Controllare il CAP'} />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  /**
   * Delete customer modal
   */
  const deleteCustomerMarkup = modalDeleteCustomerActive && (
    <Modal
      open={modalDeleteCustomerActive}
      onClose={handleDeleteCustomerModalChange}
      title="Elimina cliente"
      primaryAction={{
        content: 'Elimina',
        onAction: handleDeleteCustomer,
        destructive: true,
        loading: deleteButtonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: handleDeleteCustomerModalChange,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          Sei sicuro di voler eliminare il cliente <strong>{customer?.user?.name}</strong>?
        </TextContainer>
      </Modal.Section>
    </Modal>
  );

  /**
   * Policies markup
   */
  const policiesList = [];
  for (const item of policies) {
    const classNames = dayjs(item.date_end) < dayjs() ? 'polizzeSectionBlock error' : 'polizzeSectionBlock';
    policiesList.push(
      <div key={item._id} className={classNames}>
        <Link removeUnderline url={`/policies/${item._id}`}>
          <div>
            {item.date_end ? (
              <div className="polizzeSectionSubheading">
                <Text as="p" variant="headingSm">
                  {parseDateLabel(new Date(item.date_end))}
                </Text>
              </div>
            ) : (
              <div className="polizzeSectionSubheading">
                <Text as="p" variant="headingSm">
                  <Badge>Scadenza non impostata</Badge>
                </Text>
              </div>
            )}
            <ul className="polizzeSectionUl">
              <li className="polizzeSectionLi">
                <div className="polizzeSectionMargin">
                  <div className="polizzeSectionText">
                    <div className="polizzeSectionPoint"></div>
                    <p className="polizzeSectionP">{item.line_items[0].name}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Link>
      </div>,
    );
  }

  const policiesMarkup = (
    <>
      {/** Polizze */}
      <div className="polizzeSectionHeading">
        <Text variant="headingMd" as="h2">
          Polizze
        </Text>
      </div>
      <div>
        <div className="polizzeSectionInitial">
          <div className="polizzeSectionAvatar">
            <Avatar customer={false} size="medium" initials={'PO'} name={'_id'} />
          </div>
        </div>
        <div>{policiesList}</div>
      </div>
    </>
  );

  /**
   * Quotes markup
   */
  const quotesList = [];
  for (const item of quotes) {
    quotesList.push(
      <div key={item._id} className="polizzeSectionBlock">
        <Link removeUnderline url={`/quotes/${item._id}`}>
          <div>
            {item.date_end && (
              <div className="polizzeSectionSubheading">
                <Text as="p" variant="headingMd">
                  {parseDateLabel(new Date(item.date_end))}
                </Text>
              </div>
            )}
            <ul className="polizzeSectionUl">
              <li className="polizzeSectionLi">
                <div className="polizzeSectionMargin">
                  <div className="polizzeSectionText">
                    <div className="polizzeSectionPoint"></div>
                    <p className="polizzeSectionP">{item.line_items[0].name}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Link>
      </div>,
    );
  }

  const quotesMarkup = (
    <>
      <div className="polizzeSectionHeading">
        <Text variant="headingMd" as="h2">
          Preventivi
        </Text>
      </div>
      <div>
        <div className="polizzeSectionInitial">
          <div className="polizzeSectionAvatar">
            <Avatar customer={false} size="medium" initials={'PR'} name={'_id'} />
          </div>
        </div>
        <div>{quotesList}</div>
      </div>
    </>
  );

  /**
   * Copy handlers
   */
  // Copy e-mail
  const handleCopyEmail = useCallback(() => {
    navigator.clipboard.writeText(customer?.user?.email ?? '');
    toggleToastCopyActive();
  }, [customer]);

  // Copy phone
  const handleCopyPhone = useCallback(() => {
    navigator.clipboard.writeText(customer?.user?.phone ?? '');
    toggleToastCopyActive();
  }, [customer]);

  // Copy address
  const handleCopyAddress = useCallback(() => {
    navigator.clipboard.writeText(`${customer?.address?.line ?? ''} ${customer?.address?.city ?? ''} ${customer?.address?.zip ?? ''}`);
    toggleToastCopyActive();
  }, [customer]);

  // Copy CF
  const handleCopyCF = useCallback(() => {
    navigator.clipboard.writeText(customer?.fiscal_code ?? '');
    toggleToastCopyActive();
  }, [customer]);

  // Copy PIVA
  const handleCopyVAT = useCallback(() => {
    navigator.clipboard.writeText(customer?.vat ?? '');
    toggleToastCopyActive();
  }, [customer]);

  // Copy ATECO
  const handleCopyATECO = useCallback(() => {
    navigator.clipboard.writeText(customer?.ateco ?? '');
    toggleToastCopyActive();
  }, [customer]);

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati con successo." onDismiss={toggleActive} /> : null;
  const toastCustomerDeleted = deleted ? <Toast content="Il cliente è stato eliminato con successo." onDismiss={() => setDeleted(false)} /> : null;

  const toastFileUploadMarkup = fileUploadActive ? <Toast content="Il file è stato caricato con successo" onDismiss={toggleFileUploadActive} /> : null;
  const toastFileDeletedMarkup = fileDeletedActive ? <Toast content="Il file è stato rimosso con successo" onDismiss={toggleFileDeletedActive} /> : null;

  const toastCopyMarkup = toastCopyActive ? <Toast content="Il dato è stato copiato" onDismiss={toggleToastCopyActive} /> : null;

  const missingUserMarkup = missingUserError && (
    <Layout.Section>
      <Banner title="Errore" status="critical">
        <p>Il cliente non ha un account utente associato.</p>
      </Banner>
    </Layout.Section>
  );

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const customerOrdersErrorMarkup = customerOrdersError && (
    <Layout.Section>
      <Banner title="Non è possibile eliminare il cliente" status="critical" onDismiss={() => setCustomerOrdersError(false)}>
        <p>Non è stato possibile procedere poiché il cliente ha associati degli ordini.</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page
      backAction={{ content: 'Customer', url: '/customers' }}
      title={customer?.user?.lastname ? `${customer.user.lastname} ${customer.user.name}` : customer?.user?.name}
      titleMetadata={renderCustomerStatusBadge(customer?.user?.status ?? 'active')}
      pagination={
        {
          // hasNext: nextUrl && true,
          // hasPrevious: prevUrl && true,
          // nextURL: nextUrl && `/customers/${nextUrl}`,
          // previousURL: prevUrl && `/customers/${prevUrl}`,
        }
      }
      primaryAction={{
        content: 'Crea preventivo/polizza',
        onAction: handleModalQuoteChange,
        disabled: missingUserError,
      }}
      secondaryActions={[
        {
          content: 'Modifica',
          onAction: handleModalChange,
          disabled: missingUserError,
        },
        {
          content: 'Elimina',
          onAction: handleDeleteCustomerModalChange,
        },
      ]}
    >
      <Layout>
        {/* Banner */}
        {missingUserMarkup}
        {saveErrorMarkup}
        {customerOrdersErrorMarkup}

        {/* Panoramica cliente */}
        <Layout.Section>
          <LegacyCard title="Dettagli cliente">
            <LegacyCard.Section>
              <LegacyStack>
                <LegacyStack.Item>
                  <Text variant="headingMd" as="h2">
                    <Avatar customer={false} size="medium" name={'_id'} />
                  </Text>
                </LegacyStack.Item>
                <LegacyStack.Item fill>
                  <VerticalStack>
                    <h3>
                      <Text as="span" fontWeight="semibold">
                        {customer?.user?.lastname ? `${customer.user.name} ${customer.user.lastname}` : customer?.user?.name}
                      </Text>
                    </h3>
                    <div>
                      {customer?.type === 'private' && (
                        <Text as="span" color="subdued">
                          Professione: {job ? job : <Badge>Mancante</Badge>}
                        </Text>
                      )}
                    </div>
                    <LegacyStack alignment="center" spacing="extraTight">
                      <LegacyStack.Item fill>
                        <Text as="span" color="subdued">
                          CF: {customer?.fiscal_code ? customer?.fiscal_code : <Badge>Mancante</Badge>}
                        </Text>
                      </LegacyStack.Item>
                      <Button onClick={handleCopyCF} icon={ClipboardMinor as any} plain />
                    </LegacyStack>
                    {customer?.type === 'company' && (
                      <div>
                        <LegacyStack alignment="center" spacing="extraTight">
                          <LegacyStack.Item fill>
                            <Text as="span" color="subdued">
                              P.IVA: {customer?.vat ? customer?.vat : <Badge>Mancante</Badge>}
                            </Text>
                          </LegacyStack.Item>
                          <Button onClick={handleCopyVAT} icon={ClipboardMinor as any} plain />
                        </LegacyStack>
                        <LegacyStack alignment="center" spacing="extraTight">
                          <LegacyStack.Item fill>
                            <Text as="span" color="subdued">
                              ATECO: {customer?.ateco ? customer?.ateco : <Badge>Mancante</Badge>}
                            </Text>
                          </LegacyStack.Item>
                          <Button onClick={handleCopyATECO} icon={ClipboardMinor as any} plain />
                        </LegacyStack>
                      </div>
                    )}
                    <div>
                      <Text as="span" color="subdued">
                        Cliente dal: {(customer?.date_created ? `${customer.date_created} ` : customer?.date_created)?.toString().split('T')[0]}
                      </Text>
                    </div>
                    <div>
                      <Text as="span" color="subdued">
                        Lingua: {getLanguageLabel(customer?.user?.language ?? 'it')}
                      </Text>
                    </div>
                  </VerticalStack>
                </LegacyStack.Item>
              </LegacyStack>
              <br />
              {customer?.notes ? (
                <div className="notaContainer">
                  <LegacyStack>
                    <Labelled id={''} label={undefined}>
                      Note sul cliente:
                    </Labelled>
                    <p>{customer?.notes}</p>
                  </LegacyStack>
                </div>
              ) : (
                ''
              )}
            </LegacyCard.Section>
            {customer?.type === 'private' && (
              <LegacyCard.Section>
                <div>
                  <p>
                    <strong>Data di nascita: </strong>
                    <Text as="span" color="subdued">
                      {birthDate ? parseDateLabel(dayjs(birthDate).toDate()) : <Badge>Mancante</Badge>}
                    </Text>
                  </p>
                  <p>
                    <strong>Città di nascita: </strong>
                    <Text as="span" color="subdued">
                      {birthCity ? birthCity.label : <Badge>Mancante</Badge>}
                    </Text>
                  </p>
                  <p>
                    <strong>Sesso: </strong>
                    <Text as="span" color="subdued">
                      {gender ? parseCustomerGender(gender) : <Badge>Mancante</Badge>}
                    </Text>
                  </p>
                </div>
              </LegacyCard.Section>
            )}
          </LegacyCard>

          {/* Policies Markup */}
          {policies.length > 0 && <div className="polizzeMarkup">{policiesMarkup}</div>}
          {/* Quotes mMrkup */}
          {quotes.length > 0 && <div className="polizzeMarkup">{quotesMarkup}</div>}

          {/* File upload */}
          <LegacyCard title="Documenti vari">
            <LegacyCard.Section>{fileUploadJSX}</LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>

        {/** Second column */}
        <Layout.Section secondary>
          {/* Customer contacts */}
          <LegacyCard title="Contatti cliente">
            <LegacyCard.Section>
              {customer?.user?.email ? (
                <LegacyStack>
                  <LegacyStack.Item fill>
                    <p>{customer?.user.email}</p>
                  </LegacyStack.Item>
                  <Button onClick={handleCopyEmail} icon={ClipboardMinor as any} plain />
                </LegacyStack>
              ) : (
                <p>
                  <Badge>Mancante</Badge>
                </p>
              )}
            </LegacyCard.Section>
            <LegacyCard.Section title="Telefono">
              {customer?.user?.phone ? (
                <LegacyStack>
                  <LegacyStack.Item fill>
                    <p>{customer?.user.phone}</p>
                  </LegacyStack.Item>
                  <Button onClick={handleCopyPhone} icon={ClipboardMinor as any} plain />
                </LegacyStack>
              ) : (
                <p>
                  <Badge>Mancante</Badge>
                </p>
              )}
            </LegacyCard.Section>
            <LegacyCard.Section
              title="Indirizzo"
              actions={[
                {
                  content: customer?.address ? 'Modifica' : 'Aggiungi',
                  onAction: handleModalAddressChange,
                  disabled: missingUserError,
                },
              ]}
            >
              {customer?.address && customer?.address.line.length > 0 ? (
                <LegacyStack>
                  <LegacyStack.Item fill>
                    <LegacyStack vertical spacing="none">
                      <p>{customer.address.line}</p>
                      <p>
                        {customer.address.zip} {customer.address.city}
                      </p>
                      <p>{countryOptions.find((item) => item.value === customer?.address?.country)?.label ?? <Badge>Mancante</Badge>}</p>
                    </LegacyStack>
                  </LegacyStack.Item>
                  <Button onClick={handleCopyAddress} icon={ClipboardMinor as any} plain />
                </LegacyStack>
              ) : (
                <p>
                  <Badge>Mancante</Badge>
                </p>
              )}
            </LegacyCard.Section>
          </LegacyCard>

          {/* Organization */}
          <LegacyCard sectioned title="Organizzazione">
            <LegacyStack vertical>
              <Text as="p" fontWeight="semibold">
                {customer?.dealer?.name}
              </Text>
            </LegacyStack>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section />
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  /**
   * Error
   */
  const errorPageMarkup = (
    <Page>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <EmptyState
              heading="Nessun cliente presente a questo indirizzo"
              image="https://cdn.shopify.com/shopifycloud/web/assets/v1/08f1b23a19257042c52beca099d900b0.svg"
            >
              <p>Controlla l'URL e riprova oppure usa la barra di ricerca per trovare ciò di cui hai bisogno.</p>
            </EmptyState>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : error ? errorPageMarkup : actualPageMarkup;

  /**
   * Save bar
   */
  const contextualSaveBarMarkup =
    customer?.user?.status === 'draft' ? (
      <ContextualSaveBar
        message="Modifiche non salvate"
        saveAction={{
          onAction: () => handleSave(true),
          loading: buttonSpinning,
          content: 'Attiva',
        }}
        discardAction={{
          onAction: handleDeleteCustomerModalChange,
          content: 'Elimina',
        }}
        contextControl={contextControlMarkup}
      />
    ) : null;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {loadingMarkup}
      {pageMarkup}
      {modalMarkup}
      {/* {modalEmailMarkup}
      {modalPhoneMarkup} */}
      {modalAddressMarkup}
      {deleteCustomerMarkup}

      {toastMarkup}
      {toastCustomerDeleted}
      {toastPolicyOrQuoteMarkup}
      {toastFileUploadMarkup}
      {toastFileDeletedMarkup}
      {toastCopyMarkup}
      <NewCustomerPolicy
        modalActive={modalQuoteActive}
        handleClose={handleModalQuoteChange}
        customerId={customer?.user?._id}
        setNewPolicyOrQuoteSuccess={setNewPolicyOrQuoteSuccess}
        setIsPolicy={setIsPolicy}
        setUpdated={setUpdate}
      />
    </Frame>
  );
}
