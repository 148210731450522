import { Badge } from '@shopify/polaris';
import axios from 'axios';
import React from 'react';
import { OrderStatus } from '../types';
import { getLanguage } from '@fluentui/react';

// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
};

// set the token and user from the session storage
export const setUserSession = (token: string, user: any) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
};

/**
 * Verify authentication
 */
export const verifyToken = async () => {
  try {
    const response = await axios.post(
      (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/auth',
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
        timeout: 5000,
      },
    );
    const data = response.data;
    if (data.status === 'success') {
      return { status: true, data: data.data };
    } else {
      return { status: false, data: null };
    }
  } catch (err) {
    console.log(`⚡️ An error occurred: ${err}`);
    return false;
  }
};

/**
 * Parse date label
 * @param date
 * @returns
 */
export const parseDateLabel = (date: Date) => {
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
};

/**
 * Convert date in format DD and short month name
 * @param date
 * @returns string
 */
export const parseDateLabelChart = (date: Date) => {
  return `${('0' + date.getDate()).slice(-2)} ${date.toLocaleString('it-IT', { month: 'short' })}`;
};

/**
 * Get initials from full name
 */
export const getInitials = (name: string): string => {
  const initials = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);
  return String(initials?.join(''));
};

const branchOptions = [
  { label: "RC Verso Terzi e Prestatori d'Opera", value: '0' },
  { label: 'RC del Prodotto', value: '1' },
  { label: 'Sperimentazione Clinica', value: '2' },
  { label: 'Aviation', value: '3' },
  { label: 'Eventi Metereologici Catastrofali e Non', value: '4' },
  { label: 'Cancellazione Eventi', value: '5' },
  { label: 'Over Redemption', value: '6' },
  { label: 'Prize Indemnity', value: '7' },
  { label: 'RCT/O', value: '8' },
  { label: 'RC Patrimoniale', value: '9' },
  { label: 'Colpa Grave Dirigenti e Dipendenti di Enti Pubblici', value: '10' },
  { label: 'Colpa Grave Dirigenti e Dipendenti del Servizio Sanitario Nazionale', value: '11' },
  { label: 'Trattative Private', value: '12' },
  { label: 'Partecipazione a Gare', value: '13' },
  { label: 'Trasporti', value: '14' },
  { label: 'Project Cargo', value: '15' },
  { label: 'Private Collectors', value: '16' },
  { label: 'SicurArredo', value: '17' },
  { label: 'I Grandi Vini', value: '18' },
];
export const bindRamo = (ramo: number) => {
  for (const item of branchOptions) {
    if (Number(ramo) === Number(item.value)) return item.label;
  }
};

/**
 * Render status badge
 * @param status
 * @returns
 */
export const renderOrderStatusBadge = (status: string | undefined | null) => {
  let statusMarkup;
  switch (status) {
    case 'quote':
      statusMarkup = (
        <Badge status="info" progress="incomplete">
          Preventivo
        </Badge>
      );
      break;
    case 'quote_accepted':
      statusMarkup = (
        <Badge status="attention" progress="partiallyComplete">
          Preventivo accettato
        </Badge>
      );
      break;
    case 'quote_rejected':
      statusMarkup = (
        <Badge status="attention" progress="partiallyComplete">
          Preventivo rifiutato
        </Badge>
      );
      break;
    case 'paid':
      statusMarkup = (
        <Badge status="success" progress="partiallyComplete">
          Pagato
        </Badge>
      );
      break;
    case 'expired':
      statusMarkup = (
        <Badge status="attention" progress="complete">
          Scaduta
        </Badge>
      );
      break;
    case 'emitted':
      statusMarkup = (
        <Badge status="success" progress="complete">
          Emesso
        </Badge>
      );
      break;
    case 'to_be_quoted':
      statusMarkup = (
        <Badge status="info" progress="incomplete">
          Da quotare
        </Badge>
      );
      break;
    case 'to_be_completed':
      statusMarkup = (
        <Badge status="info" progress="incomplete">
          Da completare
        </Badge>
      );
      break;
    case 'to_be_completed_quote':
      statusMarkup = (
        <Badge status="info" progress="incomplete">
          Da completare
        </Badge>
      );
      break;
    case 'to_be_confirmed':
      statusMarkup = (
        <Badge status="new" progress="incomplete">
          Da confermare
        </Badge>
      );
      break;
    case 'blocked':
      statusMarkup = (
        <Badge status="critical" progress="incomplete">
          Bloccato
        </Badge>
      );
      break;
    default:
      statusMarkup = '';
      break;
  }

  return statusMarkup;
};

/**
 * Parse order timeline status
 * @param {OrderStatus} status
 * @returns {string}
 */
export const parseOrderTimelineStatus = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.DRAFT:
      return 'Bozza';
    case OrderStatus.QUOTE:
      return 'Preventivo';
    case OrderStatus.TO_BE_QUOTED:
      return 'Da quotare';
    case OrderStatus.QUOTE_ACCEPTED:
      return 'Preventivo accettato';
    case OrderStatus.QUOTE_REJECTED:
      return 'Preventivo rifiutato';
    case OrderStatus.TO_BE_COMPLETED:
      return 'Da completare';
    case OrderStatus.PAID:
      return 'Pagato';
    case OrderStatus.EMITTED:
      return 'Emesso';
    case OrderStatus.EXPIRED:
      return 'Scaduto';
    case OrderStatus.BLOCKED:
      return 'Bloccato';
    default:
      return '';
  }
};

/**
 * Map languages label and value
 * @param {string} language
 */
export const getLanguageLabel = (language: 'it' | 'en' | 'fr' | 'de' | 'es') => {
  switch (language) {
    case 'it':
      return 'Italiano';
    case 'en':
      return 'Inglese';
    case 'fr':
      return 'Francese';
    case 'de':
      return 'Tedesco';
    case 'es':
      return 'Spagnolo';
    default:
      return 'Italiano';
  }
};

/**
 * Render customer status badge
 * @param status
 * @returns
 */
export const renderCustomerStatusBadge = (status: 'active' | 'draft') => {
  let statusMarkup;
  switch (status) {
    case 'draft':
      statusMarkup = <Badge progress="incomplete">Bozza</Badge>;
      break;
    case 'active':
      statusMarkup = (
        <Badge status="success" progress="complete">
          Attivo
        </Badge>
      );
      break;
    default:
      statusMarkup = (
        <Badge status="success" progress="complete">
          Attivo
        </Badge>
      );
      break;
  }

  return statusMarkup;
};

/**
 * Render company type badge
 * @param {string} type
 * @returns
 */
export const renderCompanyTypeBadge = (type?: 'company' | 'broker' | 'coverholder') => {
  let typeMarkup = <Badge status="success">Compagnia</Badge>;
  switch (type) {
    case 'company':
      typeMarkup = <Badge status="success">Compagnia</Badge>;
      break;
    case 'broker':
      typeMarkup = <Badge status="info">Broker</Badge>;
      break;
    case 'coverholder':
      typeMarkup = <Badge status="attention">Coverholder</Badge>;
      break;
  }

  return typeMarkup;
};

/**
 * Render claim status badge
 * @param {string} status
 * @returns
 */
export const renderClaimStatusBadge = (status: 'draft' | 'open' | 'rejected' | 'closed') => {
  let typeMarkup = <Badge>Bozza</Badge>;
  switch (status) {
    case 'open':
      typeMarkup = (
        <Badge status="attention" progress="partiallyComplete">
          Aperto
        </Badge>
      );
      break;
    case 'rejected':
      typeMarkup = (
        <Badge status="success" progress="complete">
          Liquidato
        </Badge>
      );
      break;
    case 'closed':
      typeMarkup = <Badge progress="incomplete">Senza seguito</Badge>;
      break;
  }

  return typeMarkup;
};

/**
 * Parse company file type
 * @param {string} type
 * @returns {string}
 */
export const parseCompanyFileType = (type: string) => {
  switch (type) {
    case 'mandate':
      return 'Mandato';
    case 'commission_table':
      return 'Tabella provvigionale';
    case 'other':
      return 'Altro';
    default:
      return '';
  }
};

/**
 * Parse customer gender
 * @param {'male' | 'female'} gender
 * @returns
 */
export const parseCustomerGender = (gender: 'male' | 'female') => {
  switch (gender) {
    case 'male':
      return 'Uomo';
    case 'female':
      return 'Donna';
  }
};

/**
 * Parse order file type
 * @param {string} type
 * @returns {string}
 */
export const parseOrderFileType = (type: string) => {
  switch (type) {
    case 'quote':
      return 'Preventivo';
    case 'policy':
      return 'Polizza';
    case 'invoice':
      return 'Fattura';
    case 'renewal':
      return 'Rinnovo';
    default:
      return '';
  }
};

/**
 * Parse variant file type
 * @param {string} type
 * @returns {string}
 */
export const parseVariantFileType = (type: string) => {
  switch (type) {
    case 'information_set':
      return 'Set Informativo';
    case 'precontractual_information':
      return 'Informativa Precontrattuale';
    default:
      return '';
  }
};

/**
 * Parse customer file type
 * @param {string} type
 * @returns {string}
 */
export const parseCustomerFileType = (type: string) => {
  switch (type) {
    case 'identity':
      return "Documento d'identità";
    case 'fiscal':
      return 'Codice fiscale';
    case 'vat':
      return 'Partita IVA';
    case 'residence':
      return 'Domicilio';
    case 'bank':
      return 'IBAN';
    default:
      return '';
  }
};

/**
 * Parse claim type
 * @param {string} type
 * @returns {string}
 */
export const parseClaimType = (type: string) => {
  switch (type) {
    case 'car':
      return 'Auto';
    case 'home':
      return 'Casa';
    case 'injuries':
      return 'Infortuni';
    case 'travel':
      return 'Viaggi';
    case 'instruments':
      return 'Strumenti Musicali';
    default:
      return '';
  }
};
