import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Banner,
  Button,
  LegacyCard,
  ContextualSaveBar,
  FormLayout,
  Frame,
  Layout,
  Modal,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
  Toast,
  Badge,
  ResourceItem,
  ResourceList,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../../../components';

import './UserDetails.scss';
import axios, { AxiosError } from 'axios';
import { UserRoles } from '../../../../types';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../../../utils/PrivateRoute';
import { parseDateLabel } from '../../../../utils/Common';

import dayjs from 'dayjs';
import 'dayjs/locale/it';
dayjs.locale('it');

export function UserDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [active, setActive] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [modalChangePwdActive, setModalChangePwdActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [error, setError] = useState(false);
  const [pwdError, setPwdError] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleDeleteSuccess = useCallback(() => setDeleteSuccess((deleteSuccess) => !deleteSuccess), []);
  const [lastAccesses, setLastAccesses] = useState([]);

  const [defaultState, setDefaultState] = useState({
    name: '',
    lastname: '',
    email: '',
    role: '',
  });
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [dateCreated, setDateCreated] = useState('');

  // Role options
  const options = [];
  options.push(
    { label: 'Admin', value: UserRoles.admin },
    { label: 'Customer', value: UserRoles.customer, disabled: true },
    { label: 'Partner', value: UserRoles.partner },
    { label: 'Affinity', value: UserRoles.affinity },
    { label: 'Consultant', value: UserRoles.constultant },
  );

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  /** Discard */
  const handleDiscard = useCallback(() => {
    setName(defaultState.name);
    setLastname(defaultState.lastname);
    setEmail(defaultState.email);
    setRole(defaultState.role);
    setIsDirty(false);
  }, [defaultState]);

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.put(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/users/${params.id}`,
        {
          name: name,
          lastname: lastname ? lastname : '',
          email: email,
          role: role,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        // Update user only if it is my account
        if (user.email === defaultState.email) {
          // Redirect to login page
          const response = await axios.post(
            (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/logout',
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
              },
            },
          );
          const data = response.data;

          if (data.status === 'success') {
            localStorage.removeItem('mb__access_token');
            setActive(true);
            setTimeout(() => {
              window.location.href = '/login';
            }, 3000);
          }
        }
        setActive(true);
      } else {
        handleDiscard();
        setError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      handleDiscard();
      setError(true);
    } finally {
      setButtonSpinning(false);
    }
    setIsDirty(false);
  }, [params.id, name, lastname, email, role, user.email, defaultState.email, handleDiscard]);

  /**
   * Delete account
   */
  const handleModalChange = useCallback(async () => {
    setModalActive(!modalActive);
  }, [modalActive]);

  const handleDelete = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.delete((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/users/${params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      if (data.status === 'success') {
        // Show toast and redirect after 3 seconds
        setDeleteSuccess(true);
        setTimeout(() => {
          navigate('/admin/users');
        }, 3000);
      } else {
        setError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      handleDiscard();
      setError(true);
    } finally {
      setButtonSpinning(false);
      handleModalChange();
    }
  }, [handleDiscard, handleModalChange, history, params.id]);

  /**
   * Reset Password
   */
  const handleModalChangePwd = useCallback(async () => {
    setModalChangePwdActive(!modalChangePwdActive);
  }, [modalChangePwdActive]);

  const handleResetPassword = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.post(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/users/${params.id}/password`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        // Enable toast
        setActive(true);
      } else {
        setError(true);
      }
      setModalChangePwdActive(false);
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      handleDiscard();
      setError(true);
    } finally {
      setButtonSpinning(false);
      handleModalChangePwd();
    }
  }, [handleModalChangePwd, params.id, handleDiscard]);

  /** Handler */
  const handleNameChange = useCallback((e: string) => {
    setName(e);
    setIsDirty(true);
  }, []);
  const handleLastnameChange = useCallback((e: string) => {
    setLastname(e);
    setIsDirty(true);
  }, []);
  const handleEmailChange = useCallback((e: string) => {
    setEmail(e);
    setIsDirty(true);
  }, []);
  const handleSelectChange = useCallback((value: any) => {
    setRole(value);
    setIsDirty(true);
  }, []);

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      // contextControl={contextControlMarkup}
    />
  ) : null;

  /**
   * Fetch account
   */
  useEffect(() => {
    const fetchAccount = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/users/${params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const fetchedUser = data.data;
          const fetchedLastAccesses = data.last_accesses;
          setDefaultState({
            name: fetchedUser.name,
            lastname: fetchedUser.lastname ? fetchedUser.lastname : '',
            email: fetchedUser.email,
            role: fetchedUser.role,
          });
          setName(fetchedUser.name);
          setLastname(fetchedUser.lastname ? fetchedUser.lastname : '');
          setEmail(fetchedUser.email);
          setRole(fetchedUser.role);
          setStatus(fetchedUser.status);
          setDateCreated(fetchedUser.date_created);

          if (fetchedUser.customer) {
            setCustomerId(fetchedUser.customer);
          }

          setLastAccesses(fetchedLastAccesses || []);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAccount();
  }, [params.id]);

  /**
   * Banner & modal markup
   */
  const bannerMarkup = error && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const bannerWrongPwdMarkup = pwdError && (
    <Layout.Section>
      <Banner title="Password errata" status="critical" onDismiss={() => setPwdError(false)}>
        <p>Si è pregati di ricontrollare la password.</p>
      </Banner>
    </Layout.Section>
  );

  const modalMarkup = (
    <Modal
      open={modalActive}
      onClose={handleModalChange}
      title="Rimozione account"
      primaryAction={{
        content: 'Continua',
        onAction: handleDelete,
        destructive: true,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleModalChange,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>Si è sicuri di voler procedere con la rimozione dell'account?</p>
          <p>Una volta che si è proceduto non è possibile tornare indietro. Sarà necessario creare un nuovo account.</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );

  /** Change Pwd */
  const modalChangePwdMarkup = (
    <Modal
      open={modalChangePwdActive}
      onClose={handleModalChangePwd}
      title="Reimpostare la password"
      primaryAction={{
        content: 'Continua',
        onAction: handleResetPassword,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleModalChangePwd,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>Si è sicuri di voler procedere con la reimpostazione della password?</p>
          <p>Una volta che si è proceduto non è possibile tornare indietro.</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );

  const removeAccountButton = user.id !== params.id && (
    <Button destructive disabled={isDirty} onClick={handleModalChange}>
      Elimina account
    </Button>
  );

  /**
   * Toast markup
   */
  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati" onDismiss={toggleActive} /> : null;

  const deleteUserToastMarkup = deleteSuccess ? <Toast content="L'account è stato eliminato" onDismiss={toggleDeleteSuccess} /> : null;

  /**
   * Page markup
   */
  const resetPasswordButton = {
    content: 'Reimposta password',
    onAction: () => handleModalChangePwd(),
  };

  const navigateToCustomer = {
    content: 'Vai al cliente',
    url: `/customers/${customerId}`,
  };

  const secondaryActionsList = [];
  if (email !== user.email) secondaryActionsList.push(resetPasswordButton);
  if (customerId) secondaryActionsList.push(navigateToCustomer);

  const actualPageMarkup = (
    <Page
      title={`${defaultState.name} ${defaultState.lastname}`}
      titleMetadata={<Badge>{`Data creazione: ${parseDateLabel(new Date(dateCreated))}`}</Badge>}
      backAction={{ content: 'Utenti', url: '/admin/users' }}
      primaryAction={removeAccountButton}
      secondaryActions={secondaryActionsList}
    >
      <Layout>
        {/* Banner */}
        {bannerMarkup}
        {bannerWrongPwdMarkup}
        {/* Panoramica account */}
        <Layout.AnnotatedSection title="Dettagli account">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="Nome" value={name} onChange={handleNameChange} />
                <TextField autoComplete="on" type="text" label="Cognome" value={lastname} onChange={handleLastnameChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="email"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                  helpText="Verrà utilizzato per l'accesso all'account."
                  disabled={user.role !== 'admin' && role === 'admin'}
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Ruolo account">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label="Ruolo"
                  options={options}
                  onChange={handleSelectChange}
                  value={role}
                  helpText="In base al ruolo l'utente avrà viste diverse."
                  disabled={(user.role !== 'admin' && role === 'admin') || role === 'customer'}
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Ultimi accessi">
          <LegacyCard sectioned>
            {lastAccesses.length > 0 ? (
              <ResourceList
                resourceName={{ singular: 'accesso', plural: 'accessi' }}
                items={lastAccesses}
                renderItem={(log) => {
                  const { _id, login_time } = log;
                  const formattedDate = dayjs(login_time).format('DD/MM/YYYY HH:mm:ss');

                  return (
                    <ResourceItem
                      id={_id}
                      accessibilityLabel={`Visualizza accesso ${_id}`}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onClick={() => {}}
                    >
                      <h3>
                        <span>{formattedDate}</span>
                      </h3>
                    </ResourceItem>
                  );
                }}
              />
            ) : (
              <p>Nessun accesso recente.</p>
            )}
          </LegacyCard>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {pageMarkup}
      {modalMarkup}
      {modalChangePwdMarkup}
      {toastMarkup}
      {deleteUserToastMarkup}
    </Frame>
  );
}
