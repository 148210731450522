import { ChangeEvent, HTMLProps, useState } from 'react';

type Props = {
  selfPointer: string;
  state: number[][];
  setState: (param: any) => void;
} & HTMLProps<HTMLInputElement>;
function EditableRows({ state, setState, selfPointer, ...props }: Props) {
  const [value, setValue] = useState<string>(selfPointer && state.length ? String(state[JSON.parse(selfPointer)[0]][JSON.parse(selfPointer)[1]]) : '0');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    // Allow the input to be empty
    setValue(newValue);

    if (selfPointer && newValue !== '') {
      const arrayPointer = JSON.parse(selfPointer);
      const parsedValue = parseInt(newValue, 10);

      if (!isNaN(parsedValue)) {
        const newState = state.map((row, rowIndex) => {
          return rowIndex === arrayPointer[0] ? row.map((cell, colIndex) => (colIndex === arrayPointer[1] ? parsedValue : cell)) : row;
        });
        setState(newState);
      }
    }
  };

  return <input value={value} onChange={(e) => handleChange(e)} min={0} {...props} />;
}

export default EditableRows;
