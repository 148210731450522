import { Avatar, LegacyCard, EmptyState, Filters, ResourceItem, ResourceList, Text, Pagination } from '@shopify/polaris';
import axios from 'axios';
import React, { useCallback, useState, useEffect } from 'react';
import { User } from '../../types/user';
import { getInitials, parseDateLabel } from '../../utils/Common';

import styles from './UserList.module.css';

export function UserList({ user }: any) {
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [queryValue, setQueryValue] = useState('');
  const [sortValue, setSortValue] = useState('DATE_CREATED_DESC');
  const [items, setItems] = useState([]);
  const [frontItems, setFrontItems] = useState([]);
  const [total, setTotal] = useState(-1);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  const resourceName = {
    singular: 'utente',
    plural: 'utenti',
  };

  /**
   * Data fetching
   */
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/users', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setItems(data.data);
          setFrontItems(data.data);
          setTotal(data.data.length);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const filters: any = [];

  // Filtering
  useEffect(() => {
    const filterItems = () => {
      // Allow to search by fullname, if lastname is present, otherwise by name, lastname or email
      const filteredItems = items.filter((user: User) => {
        const fullName = user.lastname ? `${user.name} ${user.lastname}` : user.name;
        const reverseFullName = user.lastname ? `${user.lastname} ${user.name}` : user.name;

        return (
          fullName.toLowerCase().includes(queryValue.toLowerCase()) ||
          reverseFullName.toLowerCase().includes(queryValue.toLowerCase()) ||
          user.name.toLowerCase().includes(queryValue.toLowerCase()) ||
          user.lastname?.toLowerCase().includes(queryValue.toLowerCase()) ||
          user.email.toLowerCase().includes(queryValue.toLowerCase())
        );
      });

      setFrontItems(filteredItems);
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  const handleQueryChange = useCallback((e: string) => {
    setQueryValue(e);
  }, []);

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={filters}
      queryPlaceholder="Filtra utenti per nome, cognome e email"
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearAll}
    ></Filters>
  );

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'DATE_CREATED_DESC') {
      const tmp = [...items];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_ASC') {
      const tmp = [...items];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
      setFrontItems(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  /**
   * Render row
   */
  function renderItem(item: User, index: string) {
    const { _id, name } = item;
    const media = <Avatar customer={false} size="medium" initials={`${getInitials(item.name)}`} name={'_id'} />;
    const url = `/admin/users/${_id}`;

    return (
      <ResourceItem id={index} url={url} media={media} sortOrder={Number(index)} accessibilityLabel={`View details for ${name}`}>
        <div className={styles.dataScroll}>
          <div>
            <Text as="span" fontWeight="semibold">
              {item.name} {item.lastname} {item._id === user.id && '(Io)'}
            </Text>
          </div>
          <div>
            <p>
              <Text as="span" color="subdued">
                Email: {item.email}
              </Text>
              &nbsp;&ndash;&nbsp;
              <Text as="span" color="subdued">
                Ruolo: {String(item.role).charAt(0).toUpperCase() + String(item.role).slice(1)}
              </Text>
              &nbsp;&ndash;&nbsp;
              <Text as="span" color="subdued">
                Data creazione: {parseDateLabel(new Date(item.date_created))}
              </Text>
            </p>
          </div>
        </div>
      </ResourceItem>
    );
  }

  function resolveItemIds({ id }: any) {
    return id;
  }

  /**
   * Empty list markup
   */
  const emptyListMarkup = !items.length && (
    <EmptyState heading="Gestisci i clienti" image="https://cdn.shopify.com/shopifycloud/web/assets/v1/e7b58a8b2e612fe6cf6f8c9e53830b70.svg">
      <p>Qua è dove puoi gestire le informazioni dei tuoi clienti</p>
    </EmptyState>
  );

  /**
   * Pagination markup
   */
  const paginationMarkup =
    items.length > 0 ? (
      <div className={styles.CustomerListFooter}>
        <Pagination
          hasPrevious={!isFirstPage}
          hasNext={!isLastPage}
          // onPrevious={this.handlePreviousPage}
          // onNext={this.handleNextPage}
        />
      </div>
    ) : null;

  /**
   * Markup with items
   */
  const customerListMarkup = (
    <LegacyCard>
      <ResourceList
        resourceName={resourceName}
        items={frontItems}
        renderItem={renderItem}
        emptyState={emptyListMarkup}
        selectedItems={selectedItems}
        onSelectionChange={setSelectedItems}
        resolveItemId={resolveItemIds}
        filterControl={filterControl}
        sortValue={sortValue}
        sortOptions={[
          { label: 'Data aggiunta utente (dal più recente)', value: 'DATE_CREATED_DESC' },
          { label: 'Data aggiunta utente (dal meno recente)', value: 'DATE_CREATED_ASC' },
        ]}
        onSortChange={(selected) => {
          setSortValue(selected);
        }}
        totalItemsCount={total}
        loading={isLoading}
      />
      {paginationMarkup}
    </LegacyCard>
  );

  return customerListMarkup;
}
