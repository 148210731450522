import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Button,
  LegacyCard,
  ContextualSaveBar,
  FormLayout,
  Frame,
  Icon,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
  Toast,
  Banner,
  Autocomplete,
  Text,
  LegacyStack,
  Badge,
  Modal,
} from '@shopify/polaris';

import axios from 'axios';

import { TopBarMarkup, NavigationMarkup, contextControlMarkup, NewCustomerModal, QuotationModal } from '../../../components';

import './OrderNew.scss';
import { CancelSmallMinor, CirclePlusMinor, SearchMinor } from '@shopify/polaris-icons';

import { Variant, Customer, DiscountApplication, QuotationField, OrderMetadata, OrderCommission, QuotationMetadata } from '../../../types';
import { DiscountModal } from '../DiscountModal';
import { useUser } from '../../../utils/PrivateRoute';
import { useNavigate } from 'react-router-dom';

type AutocompleteProductOption = {
  label: string;
  value: string;
  productId: string;
};

type AutocompleteCustomerOption = {
  label: string;
  value: string;
};

export function OrderNew() {
  const navigate = useNavigate();
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(true);
  const [active, setActive] = useState(false);
  const [newUserToastActive, setNewUserToastActive] = useState(false);
  const [discountModalActive, setDiscountModalActive] = useState(false);
  const [newCustomerModalActive, setNewCustomerModalActive] = useState(false);
  const [customerCreated, setCustomerCreated] = useState(false);
  const [quotationModalActive, setQuotationModalActive] = useState(false);
  const [managementPriceModalActive, setManagementPriceModalActive] = useState(false);
  const [subtotalModalActive, setSubtotalModalActive] = useState(false);
  const [autoManagementPrice, setAutoManagementPrice] = useState(true);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleNewUserActive = useCallback(() => setNewUserToastActive((newUserToastActive) => !newUserToastActive), []);

  const [saveError, setSaveError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  /**
   * Order states
   */
  const [deselectedProductOptions, setDeselectedProductOptions] = useState<AutocompleteProductOption[]>([]);
  const [selectedProductOptions, setSelectedProductOptions] = useState([]);
  const [productInputValue, setProductInputValue] = useState('');
  const [productOptions, setProductOptions] = useState(deselectedProductOptions);
  const [productLoading, setProductLoading] = useState(false);
  const [variants, setVariants] = useState<Variant[]>([]);
  const [lineItemsVariant, setLineItemsVariant] = useState<Variant[]>([]);
  const [lineItems, setLineItems] = useState<string[]>([]);

  const [variantId, setVariantId] = useState<string>('');
  const [quotationFields, setQuotationFields] = useState<QuotationField[] | null>(null);

  const [subtotalPrice, setSubtotalPrice] = useState('0,00');
  const [manualSubtotalPrice, setManualSubtotalPrice] = useState(false);
  const [subtotalQuotationPrice, setSubtotalQuotationPrice] = useState<number>(0);
  const [discountPrice, setDiscountPrice] = useState('0,00');
  const [managementPrice, setManagementPrice] = useState('');
  const [managementPriceField, setManagementPriceField] = useState('');
  const [totalPrice, setTotalPrice] = useState('0,00');

  const [variantManagementPrice, setVariantManagementPrice] = useState({
    value: '0,00',
    value_type: '',
    value_field: 'subtotal',
  });

  /** Temporary values */
  const [rawSubtotalPrice, setRawSubtotalPrice] = useState('0,00');
  const [tmpSubtotalPrice, setTmpSubtotalPrice] = useState('0,00');
  const [tmpTotalPrice, setTmpTotalPrice] = useState('0,00');

  /** Harp pricing */
  const [generaliPrice, setGeneraliPrice] = useState('0,00');
  const [sbPrice, setSbPrice] = useState('0,00');

  const [deselectedCustomerOptions, setDeselectedCustomerOptions] = useState<AutocompleteCustomerOption[]>([]);
  const [selectedCustomerOptions, setSelectedCustomerOptions] = useState([]);
  const [customerInputValue, setCustomerInputValue] = useState('');
  const [customerOptions, setCustomerOptions] = useState(deselectedCustomerOptions);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [customer, setCustomer] = useState<Customer | null>(null);

  const [notes, setNotes] = useState('');

  const [metadata, setMetadata] = useState<OrderMetadata[] | []>([]);

  // Discounts
  const [discount, setDiscount] = useState<DiscountApplication | null>(null);
  const [newDiscount, setNewDiscount] = useState<boolean>(true);

  // Commission
  const [commissions, setCommissions] = useState<OrderCommission[]>([]);

  // Empty fields
  const [emptyFields, setEmptyFields] = useState({
    lineItems: false,
    customer: false,
  });

  // Set subtotal and total price
  useEffect(() => {
    setSubtotalPrice(tmpSubtotalPrice);
    setTotalPrice(tmpTotalPrice);
  }, [tmpTotalPrice, tmpSubtotalPrice]);

  // Initialise raw subtotal price when the modal is opened
  useEffect(() => {
    setRawSubtotalPrice(subtotalPrice);
  }, [subtotalPrice, subtotalModalActive]);

  /**
   * Data fetching:
   * - fetch products
   * - fetch customers
   */
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setProductLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/products', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const variantsTmp: any[] = [];
          const productOptions = [];
          for (const product of data.data) {
            for (const variant of product.variants) {
              productOptions.push({
                value: variant._id,
                label: `${product.name} - ${variant.name}`,
                productId: product._id,
              });
              variantsTmp.push(variant);
            }
          }

          setDeselectedProductOptions(productOptions);
          setProductOptions(productOptions);

          setVariants(variantsTmp);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      } finally {
        setProductLoading(false);
      }
    };
    const fetchCustomers = async () => {
      try {
        setCustomerLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/customers', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const customerOptions = [];
          for (const customer of data.data) {
            customerOptions.push({
              value: customer._id,
              label: customer.user?.lastname ? `${customer.user.name} ${customer.user.lastname}` : customer.user?.name,
              customer: customer,
            });
          }
          setCustomerOptions(customerOptions);
          setDeselectedCustomerOptions(customerOptions);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setCustomerLoading(false);
      }
    };

    fetchProducts();
    fetchCustomers();
  }, [customerCreated]);

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);

      // Check if fields are empty
      let errFlag = false;

      if (lineItems.length === 0) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, lineItems: true }));
        errFlag = true;
      }

      if (customer === null) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, customer: true }));
        errFlag = true;
      }

      if (errFlag) {
        setButtonSpinning(false);
        return;
      }

      // Update metadata fields
      // If some quotationMetadata has type array, updated it so that each array
      // consists of subArray of quotationMetadata
      const updatedMetadata = metadata.map((metadataObj) => {
        // Returns a copy of the metadata object with the fields updated
        return {
          ...metadataObj,
          fields: metadataObj.fields.map((field) => {
            if (field.type === 'array') {
              const itemFieldLength = quotationFields ? quotationFields.find((x) => x.api_field_name === field.api_field)?.values?.length ?? 0 : 0;
              // Split the array in chunks of itemFieldLength
              const arrayValues = (field.value as QuotationMetadata[]).reduce((resultArray: QuotationMetadata[][], item, index) => {
                const chunkIndex = Math.floor(index / itemFieldLength);

                if (!resultArray[chunkIndex]) {
                  resultArray[chunkIndex] = [];
                }

                resultArray[chunkIndex].push(item);
                return resultArray;
              }, [] as QuotationMetadata[][]);

              return {
                ...field,
                value: arrayValues,
              };
            }
            return field;
          }),
        };
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL ?? '/api'}/admin/orders/new`,
        {
          line_items: lineItems,
          user: customer?.user?._id,
          notes: notes,
          discount_application: discount,
          manual_subtotal: manualSubtotalPrice,
          subtotal: Number(subtotalPrice.replace(',', '.')),
          total: Number(totalPrice.replace(',', '.')),
          management_price: Number(managementPrice.replace('.', '').replace(',', '.')),
          metadata: updatedMetadata,
          commissions: commissions,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;
      setButtonSpinning(false);

      if (data.status === 'success') {
        setActive(true);
        setTimeout(() => {
          navigate(`/quotes/${data.data._id}`);
        }, 3000);
        setIsDirty(false);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      console.log(error);
      setSaveError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [lineItems, manualSubtotalPrice, subtotalPrice, totalPrice, managementPrice, customer, notes, metadata, commissions, history]);

  /**
   * Order Handlers
   */
  const handleNotesChange = useCallback((e: string) => {
    setNotes(e);
    setIsDirty(true);
  }, []);

  // Handle discount modal
  const handleDiscountModal = useCallback(() => {
    // If discount value is defined, then we are editing the discount
    if (discount?.value !== undefined && discount?.value > 0) {
      setNewDiscount(false);
    } else {
      // Set default values
      setDiscount({
        value: 0,
        type: 'manual',
        value_type: 'fixed_amount',
        description: '',
      });

      setNewDiscount(true);
    }

    setDiscountModalActive(true);
  }, [discount]);

  // Handle edit subtotal
  const handleEditSubtotalModal = useCallback(() => {
    setSubtotalModalActive(!subtotalModalActive);
  }, [subtotalModalActive]);

  const handleRawSubtotalChange = useCallback((value: any) => {
    // Remove any leading commas
    value = value.replace(/^,+/, '');

    // Check if the price has 2 or more commas and remove the last one
    if (value.split(',').length > 2) {
      value = value.replace(/,(?=[^,]*$)/, '');
    }

    // Check if the price has 3 or more numbers after the comma and remove the last one
    if (value.split(',')[1] && value.split(',')[1].length > 2) {
      value = value.replace(/.$/, '');
    }

    // Check if there are letters or special characters and remove them
    if (value.match(/[^0-9,]/g)) {
      value = value.replace(/[^0-9,]/g, '');
    }

    // Add dot as thousand separator
    // value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    setRawSubtotalPrice(value);
    setManualSubtotalPrice(true);
  }, []);

  const subtotalPriceModal = (
    <Modal
      open={subtotalModalActive}
      onClose={handleEditSubtotalModal}
      title="Modifica subtotale"
      primaryAction={{
        content: 'Salva',
        onAction: () => {
          setSubtotalPrice(rawSubtotalPrice);
          setManualSubtotalPrice(true);
          setTotalPrice(tmpTotalPrice);
          handleEditSubtotalModal();
        },
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: () => handleEditSubtotalModal(),
        },
      ]}
    >
      <Modal.Section>
        <TextField
          label="Subtotale"
          type="currency"
          value={rawSubtotalPrice}
          placeholder="0,00"
          onChange={handleRawSubtotalChange}
          prefix="EUR"
          suffix="€"
          autoComplete="off"
          helpText="L'importo della della polizza"
        />
      </Modal.Section>
    </Modal>
  );

  /**
   * Update subtotal price, commissions and total price
   */
  useEffect(() => {
    let subtotalPriceTmp = 0;
    let totalPriceTmp = 0;
    let discountPriceTmp = 0;
    let managementPriceTmp = 0;

    // Calculate subtotal price
    for (const variant of lineItemsVariant) {
      if (variant.quotation_fields?.length === 0) {
        subtotalPriceTmp += Number(variant.price);
      } else {
        subtotalPriceTmp += subtotalQuotationPrice;
      }
    }

    // If subtotalPrice is not manually set, then use the calculated one
    if (manualSubtotalPrice) {
      subtotalPriceTmp = parseFloat(subtotalPrice.replace('.', '').replace(',', '.'));
    }

    totalPriceTmp = subtotalPriceTmp;

    // Calculate discount price
    if (discount) {
      if (discount.value_type === 'percentage') {
        discountPriceTmp = subtotalPriceTmp * (discount.value / 100);
        totalPriceTmp -= discountPriceTmp;
      } else if (discount.value_type === 'fixed_amount') {
        discountPriceTmp = discount.value;
        totalPriceTmp -= discountPriceTmp;
      }
    }

    // Add management price to total price
    if (variantManagementPrice.value_type === 'fixed_amount') {
      managementPriceTmp = parseFloat(variantManagementPrice.value.replace('.', '').replace(',', '.'));
    } else if (variantManagementPrice.value_type === 'percentage') {
      managementPriceTmp = subtotalPriceTmp * (parseFloat(variantManagementPrice.value.replace('.', '').replace(',', '.')) / 100);
    }

    // If management price is not manually set, then use the calculated one
    if (autoManagementPrice || subtotalPriceTmp !== parseFloat(subtotalPrice.replace('.', '').replace(',', '.'))) {
      setManagementPrice(managementPriceTmp.toFixed(2).replace('.', ','));
      setManagementPriceField(managementPriceTmp.toFixed(2).replace('.', ','));
    }

    // Add management price to total price
    totalPriceTmp += parseFloat(managementPrice.replace('.', '').replace(',', '.'));

    // If the variant set is Arpa Sicura, then compute the harp pricing
    if (lineItemsVariant.find((x) => x.name === 'Arpa Sicura') && metadata.length > 0) {
      // Get Valore arpa in fattura from the metadata
      const valoreArpa = metadata[0].fields.find((x) => x.label === 'Valore arpa in fattura')?.value;

      if (!valoreArpa) {
        setGeneraliPrice('0,00');
        setSbPrice('0,00');
      }

      const tasso = 0.004;
      const invoice_a = (valoreArpa as number) * tasso;
      const invoice_b = invoice_a + invoice_a * 0.1;
      const invoice_generali = invoice_b + invoice_b * 0.2125;
      const invoice_sb = subtotalPriceTmp - invoice_generali;
      setGeneraliPrice(invoice_generali.toFixed(2).replace('.', ','));
      setSbPrice(invoice_sb.toFixed(2).replace('.', ','));
    }

    // For each variant, if there is a commission, add it to commissions
    const existingCommissions = new Map<string, OrderCommission>();

    for (const variant of lineItemsVariant) {
      if (variant.commission) {
        const commission = variant.commission;
        const productId = variant._id;
        let total_value: number;

        if (commission.value_type === 'percentage') {
          total_value = parseFloat(subtotalPrice.replace(',', '.')) * (Number(commission.value) / 100);
        } else if (commission.value_type === 'fixed_amount') {
          total_value = Number(commission.value);
        } else {
          continue;
        }

        const key = `${productId}-${commission.value_type}`;
        if (!existingCommissions.has(key)) {
          existingCommissions.set(key, {
            product: productId,
            total_value: total_value,
            type: commission.value_type,
            value: Number(commission.value),
          });
        }
      }
    }

    setCommissions(Array.from(existingCommissions.values()));

    setTmpSubtotalPrice(subtotalPriceTmp.toFixed(2).replace('.', ','));
    setDiscountPrice(discountPriceTmp.toFixed(2).replace('.', ','));
    setTmpTotalPrice(totalPriceTmp.toFixed(2).replace('.', ','));
  }, [lineItemsVariant, discount, managementPrice, subtotalQuotationPrice, metadata, manualSubtotalPrice, subtotalPrice, autoManagementPrice]);

  /**
   * Handle remove line item
   */
  const handleRemoveLineItem = useCallback(
    (itemId: number) => {
      setIsDirty(true);

      let tmp: string[] | Variant[] | OrderMetadata[] | OrderCommission[] = lineItems.filter((x: any) => x !== itemId);
      setLineItems(tmp);

      tmp = lineItemsVariant.filter((x: any) => x._id !== itemId);
      setLineItemsVariant(tmp);

      // If there is a metadata with the same variantId, remove it
      const metadataIndex: number = metadata.findIndex((x) => x.product === itemId.toString());
      if (metadataIndex !== -1) {
        tmp = metadata.filter((x) => x.product !== itemId.toString());
        setMetadata(tmp);
        setSubtotalQuotationPrice(0);
      }

      // Remove commissions related to the variantId
      tmp = commissions.filter((x) => x.product !== itemId.toString());
      setCommissions(tmp);

      // Reset management price
      setManagementPriceField('0,00');
      setManagementPrice('0,00');

      // Reset variant management price
      setVariantManagementPrice({
        value: '0,00',
        value_type: '',
        value_field: 'subtotal',
      });

      // Reset harp pricing
      setGeneraliPrice('0,00');
      setSbPrice('0,00');

      setVariantId('');
    },
    [lineItems, lineItemsVariant, metadata],
  );

  /**
   * Handle quotation
   */
  const handleQuotation = useCallback((quotation_price: number) => {
    setSubtotalQuotationPrice(quotation_price);
  }, []);

  /**
   * Handle metadata
   */
  const handleMetadata = useCallback(
    (metadataObj: OrderMetadata) => {
      // Check if metadata with the same variantId already exists
      const metadataIndex: number = metadata.findIndex((x) => x.product === metadataObj.product);
      if (metadataIndex !== -1) {
        // Update metadata
        const tmp = metadata;
        tmp[metadataIndex] = metadataObj;
        setMetadata(tmp);
        setVariantId('');
        return;
      }

      setMetadata((metadata) => [...metadata, metadataObj]);
      setVariantId('');
    },
    [metadata, setVariantId],
  );

  /**
   * Products autocomplete
   */
  const updateProductText = useCallback(
    (value: string) => {
      setProductInputValue(value);

      if (!productLoading) {
        setProductLoading(true);
      }

      setTimeout(() => {
        if (value === '') {
          setProductOptions(deselectedProductOptions);
          setProductLoading(false);
          return;
        }
        const filterRegex = new RegExp(value, 'i');
        const resultOptions = deselectedProductOptions.filter((option: any) => option.label.match(filterRegex));
        setProductOptions(resultOptions);
        setProductLoading(false);
      }, 300);
    },
    [deselectedProductOptions, productLoading],
  );

  const updateProductSelection = useCallback(
    (selected: any) => {
      // Check if emptyFields is true
      if (emptyFields.lineItems) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, lineItems: false }));
      }

      const selectedText = selected.map((selectedItem: any) => {
        const matchedOption = productOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });
      setSelectedProductOptions(selected);
      setProductInputValue(selectedText[0]);

      // Add variant to lineItems
      const variantId = selected[0];
      const newLineItemsVariant = lineItemsVariant.slice();
      const newLineItems = lineItems.slice();
      // Find variant with variantId from variants array
      const variant = variants.find((variant: any) => variant._id === variantId);
      // Check if variant is already present in previous array
      const index = newLineItemsVariant.findIndex((item: any) => item._id === variantId);

      if (index === -1 && variant !== undefined) {
        newLineItemsVariant.push(variant);
        setLineItemsVariant(newLineItemsVariant);
        newLineItems.push(variantId);
        setLineItems(newLineItems);

        // If variant has quotation_fields, then add them to quotationFields
        if (variant.quotation_fields !== undefined && variant.quotation_fields.length > 0) {
          setQuotationFields(variant.quotation_fields);
        }

        // If variant has management_price, then set it
        if (variant.management_price !== undefined) {
          setVariantManagementPrice({
            value: variant.management_price.value.toLocaleString('it-IT', { minimumFractionDigits: 2 }) ?? '0,00',
            value_type: variant.management_price.value_type ?? '',
            value_field: 'subtotal',
          });

          // If management_price is fixed_amount, then add it to total price
          if (variant.management_price.value_type === 'fixed_amount') {
            setManagementPrice(variant.management_price.value.toLocaleString('it-IT', { minimumFractionDigits: 2 }));
            setManagementPriceField(variant.management_price.value.toLocaleString('it-IT', { minimumFractionDigits: 2 }));
          }
        }
      }
    },
    [emptyFields.lineItems, productOptions, lineItemsVariant, lineItems, variants],
  );

  const productTextField = (
    <Autocomplete.TextField
      onChange={updateProductText}
      label={null}
      value={productInputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca un prodotto"
      autoComplete="off"
      error={emptyFields.lineItems && 'Per favore seleziona un prodotto'}
    />
  );

  const emptyProductState = (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Nessun prodotto trovato</TextContainer>
      </div>
    </React.Fragment>
  );

  /**
   * Line items markup
   */
  const lineItemsTableMarkup =
    lineItemsVariant.length > 0 ? (
      <table className="order__productsTable">
        <thead>
          <tr>
            <th className="order__productsTableItem -left">Prodotto</th>
            <th className="order__productsTableItem -right">Totale</th>
            <th className="order__productsTableItem -left">
              <Text visuallyHidden variant="headingMd" as="h2">
                Rimuovi
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {lineItemsVariant?.map((item: any) => (
            <tr className="order__productsTableRow" key={item._id}>
              <td className="order__productsTableRowItem -left">
                <Text as="span" fontWeight="semibold">
                  {item.name}
                </Text>
              </td>
              <td className="order__productsTableRowItem -right">
                {item.quotation_fields.length > 0 ? (
                  <Button
                    onClick={() => {
                      setVariantId(item._id);
                      setQuotationModalActive(!quotationModalActive);
                    }}
                    plain
                  >
                    Preventiva
                  </Button>
                ) : (
                  `${Number(String(item.price).replace('.', ',')).toFixed(2)}€`
                )}
              </td>
              <td className="order__productsTableRowItem -left">
                <div className="order__productsTableRowItemRemove">
                  <Button plain accessibilityLabel="Remove item" onClick={() => handleRemoveLineItem(item._id)} icon={CancelSmallMinor as any} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : null;

  /**
   * Commission line item markup
   */
  const commissionLineItemMarkup =
    lineItemsVariant.length > 0 ? (
      <>
        {lineItemsVariant?.map((item: any) => (
          <div key={item._id} className="paymentRow">
            <div className="paymentRowTitle">{item.name}</div>
            <div className="paymentRowAmount">{(commissions.find((x) => x.product === item._id)?.total_value || 0).toFixed(2).replace('.', ',')} €</div>
          </div>
        ))}
      </>
    ) : null;

  /**
   * Customer autocomplete
   */
  const updateCustomerText = useCallback(
    (value: any) => {
      setCustomerInputValue(value);

      if (!customerLoading) {
        setCustomerLoading(true);
      }

      setTimeout(() => {
        if (value === '') {
          setCustomerOptions(deselectedCustomerOptions);
          setCustomerLoading(false);
          return;
        }
        const filterRegex = new RegExp(value, 'i');
        const resultOptions = deselectedCustomerOptions.filter((option: any) => option.label.match(filterRegex));
        setCustomerOptions(resultOptions);
        setCustomerLoading(false);
      }, 300);
    },
    [deselectedCustomerOptions, customerLoading],
  );

  const updateCustomerSelection = useCallback(
    (selected: any) => {
      // Check if emptyFields is true
      if (emptyFields.customer) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, customer: false }));
      }

      const selectedCustomer = selected.map((selectedItem: any) => {
        const matchedOption = customerOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption;
      });
      setSelectedCustomerOptions(selected);
      setCustomerInputValue(selectedCustomer[0].label);

      // Add customer to order
      const customer = selectedCustomer[0].customer;
      setCustomer(customer);
    },
    [customerOptions],
  );

  const customerTextField = (
    <Autocomplete.TextField
      onChange={updateCustomerText}
      label={null}
      value={customerInputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca o crea cliente"
      autoComplete="off"
      error={emptyFields.customer && 'Per favore seleziona un cliente'}
    />
  );

  const emptyCustomerState = (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Nessun cliente trovato</TextContainer>
      </div>
    </React.Fragment>
  );

  /**
   * Customer markup
   *
   * Display autocomplete if customer is not selected
   * When customer is loaded, display customer details
   */
  const customerMarkup =
    customer === null ? (
      <LegacyCard sectioned title="Cliente">
        <LegacyStack vertical>
          <Autocomplete
            actionBefore={{
              accessibilityLabel: 'Crea cliente',
              content: 'Crea un cliente',
              icon: CirclePlusMinor as any,
              wrapOverflow: true,
              onAction: () => setNewCustomerModalActive(true),
            }}
            options={customerOptions}
            selected={selectedCustomerOptions}
            onSelect={updateCustomerSelection}
            loading={customerLoading}
            textField={customerTextField}
            emptyState={emptyCustomerState}
          />
        </LegacyStack>
      </LegacyCard>
    ) : (
      <LegacyCard>
        <LegacyCard.Header title="Cliente">
          <Button
            icon={CancelSmallMinor as any}
            onClick={() => {
              setCustomer(null);
              setSelectedCustomerOptions([]);
              setCustomerInputValue('');
            }}
            plain
          />
        </LegacyCard.Header>
        <LegacyCard.Section>
          <LegacyStack vertical spacing="none">
            <Text as="span" fontWeight="semibold">
              {customer.user?.lastname ? `${customer.user.name} ${customer.user.lastname}` : `${customer.user?.name}`}
            </Text>
            {customer?.user?.email ? (
              <p>{customer.user.email}</p>
            ) : (
              <p>
                <Badge>Mancante</Badge>
              </p>
            )}
          </LegacyStack>
        </LegacyCard.Section>
        <LegacyCard.Section title="Telefono">
          <LegacyCard.Subsection>
            <LegacyStack vertical>
              {customer?.user?.phone ? (
                <p>{customer?.user.phone}</p>
              ) : (
                <p>
                  <Badge>Mancante</Badge>
                </p>
              )}
            </LegacyStack>
          </LegacyCard.Subsection>
        </LegacyCard.Section>
        <LegacyCard.Section title="Indirizzo">
          <LegacyCard.Subsection>
            {customer?.address && customer?.address.line.length > 0 ? (
              <LegacyStack vertical spacing="none">
                <p>{customer.address.line}</p>
                <p>
                  {customer.address.zip} {customer.address.city}
                </p>
                <p>{customer.address.country}</p>
              </LegacyStack>
            ) : (
              <p>
                <Badge>Mancante</Badge>
              </p>
            )}
          </LegacyCard.Subsection>
        </LegacyCard.Section>
      </LegacyCard>
    );

  /**
   * Harp pricing feature
   */
  const harpFeatureMarkup = [];
  harpFeatureMarkup.push(
    <div className="paymentRow">
      <div className="paymentRowTitle">Fattura Generali</div>
      <div className="paymentRowDetail"></div>
      <div className="paymentRowAmount">{generaliPrice} €</div>
    </div>,
  );
  harpFeatureMarkup.push(
    <div className="paymentRow">
      <div className="paymentRowTitle">Fattura Saluzzo Broker</div>
      <div className="paymentRowDetail"></div>
      <div className="paymentRowAmount">{sbPrice} €</div>
    </div>,
  );

  /**
   * Management price modal & handlers
   */
  const handleManagementPriceFieldChange = useCallback((value: any) => {
    // Check if the price has 2 or more commas and remove the last one
    if (value.split(',').length > 2) {
      value = value.replace(/,(?=[^,]*$)/, '');
    }

    // Check if the price has 3 or more numbers after the comma and remove the last one
    if (value.split(',')[1] && value.split(',')[1].length > 2) {
      value = value.replace(/.$/, '');
    }

    // Check if there are letters or special characters and remove them
    if (value.match(/[^0-9,]/g)) {
      value = value.replace(/[^0-9,]/g, '');
    }

    // Add dot as thousand separator
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    setManagementPriceField(value);

    // If the management price is set to auto, then disable it
    setAutoManagementPrice(false);
  }, []);

  const handleManagementPriceModal = useCallback(() => {
    setManagementPriceModalActive(!managementPriceModalActive);
  }, [managementPriceModalActive]);

  const managementPriceModal = (
    <Modal
      open={managementPriceModalActive}
      onClose={handleManagementPriceModal}
      title="Prezzo di gestione"
      primaryAction={{
        content: 'Salva',
        onAction: () => {
          setSubtotalPrice(tmpSubtotalPrice);
          setManagementPrice(managementPriceField);
          setTotalPrice(tmpTotalPrice);
          handleManagementPriceModal();
        },
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: () => handleManagementPriceModal(),
        },
      ]}
    >
      <Modal.Section>
        <TextField
          label="Prezzo di gestione"
          type="currency"
          value={managementPriceField}
          placeholder="0,00"
          onChange={handleManagementPriceFieldChange}
          prefix="EUR"
          suffix="€"
          autoComplete="off"
          helpText="L'importo per la gestione della polizza"
        />
      </Modal.Section>
    </Modal>
  );

  /**
   * Contextual Save Bar
   */
  const handleDiscard = useCallback(() => {
    setIsDirty(false);

    // Reset all the fields
  }, []);

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message={'Modifiche non salvate'}
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      contextControl={contextControlMarkup}
    />
  ) : null;

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="Il preventivo è stato creato con successo." onDismiss={toggleActive} /> : null;
  const toastUserMarkup = newUserToastActive ? <Toast content="Il cliente è stato creato con successo." onDismiss={toggleNewUserActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page title="Crea preventivo" backAction={{ content: 'Preventivi', url: '/quotes' }}>
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}

        <Layout.Section>
          {/* Products */}
          <LegacyCard title="Prodotti">
            <LegacyCard.Section>
              <Autocomplete
                options={productOptions}
                selected={selectedProductOptions}
                onSelect={updateProductSelection}
                loading={productLoading}
                textField={productTextField}
                emptyState={emptyProductState}
              />
            </LegacyCard.Section>
            <div>{lineItemsTableMarkup}</div>
          </LegacyCard>

          {/* Commission */}
          {commissions.length > 0 && (
            <LegacyCard title="Provvigioni">
              <LegacyCard.Section>{commissionLineItemMarkup}</LegacyCard.Section>
            </LegacyCard>
          )}

          {/* Payment */}
          <LegacyCard title="Pagamento">
            <LegacyCard.Section>
              {/* Subtotal */}
              <div className="paymentRow">
                <div className="paymentRowTitle">Subtotale</div>
                <div className="paymentRowDetail">
                  <Button plain onClick={handleEditSubtotalModal}>
                    Modifica
                  </Button>
                </div>
                <div className="paymentRowAmount">{subtotalPrice} €</div>
              </div>
              {/* Harp feature */}
              {lineItemsVariant.find((x) => x.name === 'Arpa Sicura') && harpFeatureMarkup}
              {/* Discount */}
              <div className="paymentRow">
                <div className="paymentRowTitle">
                  <Button plain onClick={handleDiscountModal}>
                    {discount?.value !== undefined && discount?.value > 0 ? 'Modifica sconto' : 'Aggiungi sconto'}
                  </Button>
                </div>
                <div className="paymentRowDetail">
                  <Text as="span" color="subdued">
                    {discount ? discount.type === 'discount_code' ? 'Codice sconto' : 'Sconto personalizzato' : <>&mdash;</>}
                  </Text>
                </div>
                <div className="paymentRowAmount">
                  <Text as="span" color="subdued">
                    {discountPrice} €
                  </Text>
                </div>
              </div>
              {/* Management amount */}
              <div className="paymentRow">
                <div className="paymentRowTitle">
                  <Button plain onClick={handleManagementPriceModal}>
                    {managementPrice !== '' ? 'Modifica gestione' : 'Aggiungi gestione'}
                  </Button>
                </div>
                <div className="paymentRowDetail">
                  <Text as="span" color="subdued">
                    {managementPrice !== '' ? 'Importo personalizzato' : <>&mdash;</>}
                  </Text>
                </div>
                <div className="paymentRowAmount">
                  <Text as="span" color="subdued">
                    {managementPrice === '' ? '0,00' : Number(managementPrice.replace('.', '').replace(',', '.')).toFixed(2).replace('.', ',')} €
                  </Text>
                </div>
              </div>
              {/* Total */}
              <div className="paymentRow">
                <div className="paymentRowTitle">
                  <Text as="span" fontWeight="semibold">
                    Totale
                  </Text>
                </div>
                <div className="paymentRowAmount">{totalPrice} €</div>
              </div>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section secondary>
          {/* Customer */}
          {customerMarkup}

          {/* Categories */}
          <LegacyCard sectioned title="Note">
            <FormLayout>
              <TextField label={null} value={notes} onChange={handleNotesChange} autoComplete="off" />
            </FormLayout>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section />
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {pageMarkup}
      {DiscountModal(discountModalActive, setDiscountModalActive, discount, setDiscount, newDiscount)}
      {NewCustomerModal(newCustomerModalActive, setNewCustomerModalActive, setCustomerCreated, setNewUserToastActive)}
      {QuotationModal(
        variantId,
        quotationFields,
        metadata.find((item: any) => item.product === variantId),
        lineItemsVariant.find((item: any) => item._id === variantId)?.pricing_rules,
        quotationModalActive,
        setQuotationModalActive,
        handleQuotation,
        handleMetadata,
      )}
      {managementPriceModal}
      {subtotalPriceModal}
      {toastMarkup}
      {toastUserMarkup}
    </Frame>
  );
}
